import React from 'react';
import {Box, Typography} from "@mui/material";
import {MessageContent, MessageErrorContent, MessageErrorTitle, MessageTitle} from "../styled";

const ErrorMessageComponent = ({message}) => {
    return (
        <Box>
            <MessageTitle>
                Ассистент: Ошибка
            </MessageTitle>
            <MessageContent>
                {message.errorMessage}
            </MessageContent>
            {message.errorCode && (
                <MessageErrorTitle>
                    Код ошибки: {message.errorCode}
                </MessageErrorTitle>
            )}
            {message.errorDetails && (
                <MessageErrorContent>
                    Детали: {message.errorDetails}
                </MessageErrorContent>
            )}
        </Box>
    );
};

export default ErrorMessageComponent;
