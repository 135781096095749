import React from 'react';
import {Box, useTheme} from "@mui/material";
import {MessageContent, MessageTitle} from "../styled";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

const UserMessageComponent = ({message}) => {
    const { t, i18n } = useTranslation();

    const theme = useTheme();
    return (
        <Box>
            <MessageTitle>
                {t('my_chats_you_title')}
            </MessageTitle>
            <MessageContent>
                {message.text}
            </MessageContent>
        </Box>
    );
};

export default UserMessageComponent;
