import React, {useState} from 'react';
import {Box, IconButton, OutlinedInput, useMediaQuery, useTheme} from "@mui/material";
import {tokens} from "../../theme";
import ConversationSidebar from "./Sidebar/ConversationSidebar";
import { TextareaAutosize } from '@mui/base/TextareaAutosize';

import {SendOutlined} from "@mui/icons-material";
import ConversationHeader from "./ConversationHeader";
import {useGetConversationQuery, useSendMessageMutation} from "../../store/emptySplitApi";
import {Controller, useForm} from "react-hook-form";
import ConversationMessages from "./ConversationMessages";
import {useLocation, useParams} from "react-router-dom";
import EmptyConversation from "./EmptyConversation";
import ConversationCreationDialog from "./Sidebar/ConversationCreationDialog";
import {useSelector} from "react-redux";
import ConversationsSidebarDrawer from "../global/ConversationsSidebarDrawer";
import {ROUTES} from "../../App";
import {ConversationMessageInput} from "./styled";
import {useTranslation} from "react-i18next";

const Conversation = () => {
    const { t, i18n } = useTranslation();

    const location = useLocation();

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const {conversationId} = useParams();
    const {data: conversationData = {}, error} = useGetConversationQuery(conversationId, {
        skip: !conversationId,
    });

    const [sendMessage, {isLoading}] = useSendMessageMutation();
    const [isCreationDialogOpen, setIsCreationDialogOpen] = useState(false);

    const isSidebarOpen = useSelector(state => state.application.isSidebarOpen)
    const matches = useMediaQuery(theme.breakpoints.down('md'));

    const {
        register,
        handleSubmit,
        setError,
        reset,
        control,
    } = useForm({
        defaultValues: {
            text: "",
        },
        mode: 'onSubmit',
    });

    const onSubmit = (data) => {
        try {
            sendMessage({
                conversationId: conversationId,
                text: data.text,
            }).then((result) => {
                if (result.error) {
                    setError("text", {
                        type: "manual",
                        message: result.error.data.message,
                    });
                }
            });
        } catch (error) {
            console.log(`error: ${JSON.stringify(error)}`)
        }

        reset();
    }

    return (
        <Box
            sx={{
                backgroundColor: "#FAFAFA",
                display: "flex",
                height: matches && location.pathname !== ROUTES.CONVERSATIONS ? "calc(100vh - 85px)" : "100vh",
            }}
        >
            {
                matches
                    ? <ConversationsSidebarDrawer setIsCreationDialogOpen={setIsCreationDialogOpen}/>
                    : <ConversationSidebar setIsCreationDialogOpen={setIsCreationDialogOpen}/>
            }

            {!conversationId ? (
                <EmptyConversation setIsCreationDialogOpen={setIsCreationDialogOpen}/>
            ) : (
                <Box
                    sx={{
                        flexGrow: 1,
                        flexShrink: 1,
                        backgroundColor: "#FAFAFA",
                        color: colors.grey[100],
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <ConversationHeader conversationId={conversationId} conversationTitle={conversationData.title}/>
                    <ConversationMessages/>

                    <form
                        onSubmit={handleSubmit(onSubmit)}
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginTop: 'auto',
                            width: matches ? '100vw' : undefined,
                        }}
                    >
                        <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            padding="0.5rem 1rem"
                            borderBottom={`0.1px solid ${colors.grey[100]}`}
                            borderRadius="10px"
                            sx={{
                                minWidth: "95%",
                                backgroundColor: colors.aliceBlue[700],
                                margin: "0.5rem auto",
                                "& .MuiInputLabel-root": {},
                                "& .MuiFormLabel-filled": {
                                    color: `#FAFAFA !important`,
                                    fontWeight: "500",
                                    fontSize: "0.9rem !important",
                                },
                                "& .MuiInputLabel-shrink": {
                                    color: `#FAFAFA !important`,
                                    fontWeight: "500",
                                    fontSize: "0.9rem !important",
                                },
                                "& .MuiInputLabel-formControl": {
                                    color: `${colors.primary[100]}`,
                                },
                                "& .MuiOutlinedInput-root": {
                                    borderRadius: "10px !important",
                                },
                                "& .MuiOutlinedInput-input:-webkit-autofill": {
                                    WebkitBoxShadow: "0 0 0 100px #96cae8 inset",
                                    WebkitTextFillColor: "#FAFAFA !important",
                                },
                            }}
                        >
                            <Controller
                                render={({field}) => (
                                    <ConversationMessageInput
                                        maxRows={10}
                                        placeholder={t('my_chats_chat_input_placeholder')}
                                        {...field}
                                    />
                                )
                                }
                                name={'text'}
                                control={control}
                            />

                            <IconButton
                                type="submit"
                            >
                                <SendOutlined fontSize="large"/>
                            </IconButton>
                        </Box>
                    </form>
                </Box>
            )}
            <ConversationCreationDialog
                isOpen={isCreationDialogOpen}
                onClose={() => setIsCreationDialogOpen(false)}
                colors={colors}
            />
        </Box>
    );
};

export default Conversation;