import React from 'react';
import {Box, Typography} from "@mui/material";
import QueryResult from "../QueryResult";
import {Prism as SyntaxHighlighter} from 'react-syntax-highlighter';
import {MessageTitle} from "../styled";
import {useTranslation} from "react-i18next";

const SystemMessageComponent = ({message}) => {
    const {t, i18n} = useTranslation();

    return (
        <Box>
            <MessageTitle>
                {t('my_chats_assistant_answer_answer_title')}
            </MessageTitle>
            <Box sx={{ml: "20px"}}>

                {message.generatedQuery !== 'NONE_SQL' ?
                    (
                        <>
                            <Typography variant="h6" fontWeight={600} mt="12px">
                                {t('my_chats_assistant_answer_answer_subtitle_sql')}
                            </Typography>
                            <SyntaxHighlighter language="sql">
                                {message.generatedQuery}
                            </SyntaxHighlighter>
                        </>
                    )
                    : undefined
                }
                {message.responseConfidence && (
                    <Typography variant="h6" fontWeight={600} mt="12px">
                        {t('my_chats_assistant_answer_low-confidence_confidence-text')}: {message.responseConfidence * 100}%
                    </Typography>
                )}


                {
                    message.generatedQuery !== 'NONE_SQL' ?
                        (
                            <>
                                <Typography variant="h6" fontWeight={600} mt="12px">
                                    {t('my_chats_assistant_answer_answer_subtitle_table')}
                                </Typography>
                                <QueryResult data={message.queryResult}/>
                            </>
                        )
                        : undefined
                }

                {!message.dataExplanation ? null : (
                    <>
                        <Typography variant="h6" fontWeight={600} mt="12px">
                            {t('my_chats_assistant_answer_answer_subtitle_explanation')}
                        </Typography>
                        <Typography sx={{fontSize: "20px", whiteSpace: "pre-wrap"}}>
                            {message.dataExplanation}
                        </Typography>
                    </>
                )}
            </Box>
        </Box>
    );
};

export default SystemMessageComponent;
