const dbConnectionEndpointsBuilder = (builder) => {
    return {
        createDbConnection: builder.mutation({
            query: (body) => ({
                url: 'dbConnection',
                method: 'POST',
                body
            }),
            invalidatesTags: ['DbConnections'],
            transformResponse(baseQueryReturnValue, meta, arg) {
                return baseQueryReturnValue;
            },
            transformErrorResponse(baseQueryError, meta, arg) {
                return baseQueryError;
            }
        }),
        testDbConnection: builder.mutation({
            query: (body) => ({
                url: 'dbConnection/testDbConnection',
                method: 'POST',
                body
            }),
            transformResponse(baseQueryReturnValue, meta, arg) {
                return baseQueryReturnValue;
            },
            transformErrorResponse(baseQueryError, meta, arg) {
                return baseQueryError;
            }
        }),
        getDbConnections: builder.query({
            query: () => ({
                url: 'dbConnection',
                method: 'GET',
            }),
            providesTags: (result) =>
                result
                    ? [...result.map(({ id }) => ({ type: 'DbConnections', id })), 'DbConnections']
                    : ['DbConnections'],
            transformResponse(baseQueryReturnValue, meta, arg) {
                return baseQueryReturnValue;
            },
        }),
        getDbConnectionLabels: builder.query({
            query: () => ({
                url: 'dbConnection',
                method: 'GET',
            }),
            providesTags: (result) =>
                result
                    ? [...result.map(({ id }) => ({ type: 'DbConnections', id })), 'DbConnections']
                    : ['DbConnections'],
            transformResponse(baseQueryReturnValue, meta, arg) {
                return baseQueryReturnValue.map(({_id, name}) => ({label: name, dbConnection: _id}));
            },
        }),
        getDbConnection: builder.query({
            query: (id) => (
                {
                    url: `dbConnection/${id}`,
                    method: 'GET',
                }
            ),
            transformResponse(baseQueryReturnValue, meta, arg) {
                return baseQueryReturnValue;
            },
            transformErrorResponse(baseQueryError, meta, arg) {
                return baseQueryError;
            }
        }),
        updateDbConnection: builder.mutation({
            query: ({id, body}) => ({
                url: `dbConnection/${id}`,
                method: 'PUT',
                body
            }),
            invalidatesTags: ['DbConnections', 'DbConnectionLabels']
        }),
        deleteDbConnection: builder.mutation({
            query: (id) => ({
                url: `dbConnection/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['DbConnections', 'DbConnectionLabels']
        }),
    }
};

export default dbConnectionEndpointsBuilder;