import {Box, Button, ListItem, Tooltip, Typography, useTheme} from "@mui/material";
import {Reply} from "@mui/icons-material";
import React from "react";
import {tokens} from "../../../theme";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

const RequestItem = ({userConnectedMessage, cancelQuestionToAnalystHandler}) => {
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
        <ListItem disablePadding>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "stretch",
                    gap: "1rem",
                    padding: "1rem",
                    width: "100%",
                    borderBottom: `0.1px solid ${colors.grey[100]}`,
                }}
            >
                <Typography variant="h5" color={"#141414"}>
                    {userConnectedMessage}
                </Typography>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                        gap: "2rem",
                        "& .MuiButton-root": {
                            color: "#FAFAFA",
                            backgroundColor: colors.aliceBlue[600],
                            fontWeight: "500",
                            fontSize: "0.7rem",
                        },
                        "& .MuiButton-root:hover": {
                            backgroundColor: '#56a9da',
                        },
                        "& .MuiTooltip-popper": {
                            fontSize: "1rem !important"
                        },
                        [theme.breakpoints.down('sm')]: {
                            flexDirection: "column",
                            gap: '10px',
                            alignItems: 'flex-end',
                        }
                    }}
                >
                    <Tooltip
                        title={
                            <Typography sx={{fontSize: "0.8rem"}}>
                                {t('my_chats_analytics_window_cancel_request_hint')}
                            </Typography>
                        }
                        leaveDelay={200}
                        arrow={true}
                        PopperProps={{style: {zIndex: 2010}}}
                    >
                        <Button
                            variant="outlined"
                            endIcon={<Reply sx={{color: "#FAFAFA"}}/>}
                            onClick={cancelQuestionToAnalystHandler}
                        >
                            {t('my_chats_analytics_window_cancel_request')}
                        </Button>
                    </Tooltip>
                    <Tooltip
                        title={
                            <Typography sx={{fontSize: "0.8rem"}}>
                                {t('my_chats_analytics_window_increase_priority_hint')}
                            </Typography>
                        }
                        leaveDelay={200}
                        arrow={true}
                        sx={{
                            fontSize: "3rem",
                        }}
                        PopperProps={{style: {zIndex: 2010}}}
                    >
                        <Button
                            variant="outlined"
                            endIcon={<Reply sx={{color: "#FAFAFA"}}/>}
                        >
                            {t('my_chats_analytics_window_increase_priority')}
                        </Button>
                    </Tooltip>
                </Box>
            </Box>
        </ListItem>
    )
};

export default RequestItem;