import {Alert, Box, Button, Snackbar, Typography, useMediaQuery, useTheme} from "@mui/material";
import * as yup from "yup";
import Header from "../../components/Header";
import React, {useEffect, useState} from "react";
import {useCreateUserMutation, useGetOrganizationLabelsQuery, useGetTeamLabelsQuery,} from "../../store/emptySplitApi";
import {tokens} from "../../theme";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import StyledFormBox from "../../components/StyledFormBox";
import StyledFormInput from "../../components/StyledFormInput";
import StyledMultiSelectInput from "../../components/StyledMultiSelectInput";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";


const UserForm = () => {
    const {t, i18n} = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const role = useSelector(state => state.auth?.user?.user?.role)

    const userSchema = yup.object().shape({
        fullname: yup
            .string()
            .min(5, t('user_creation_field1_min_length_message'))
            .matches(/^[\p{L}]+(?:[\s'-][\p{L}]+)*$/u, {
                message: t('user_creation_field1_matches_message'),
                excludeEmptyString: true
            })
            .required(t('user_creation_field2_required_message')),
        email: yup
            .string()
            .email(t('user_creation_field1_matches_message'))
            .required(t('user_creation_field2_required_message')),
        role: yup
            .string()
            .required(t('user_creation_field3_required_message')),
        password: yup
            .string()
            .required(t('user_creation_field4_required_message')),
        organization: yup
            .string()
            .when('role', {
                is: (roleValue) => roleValue !== 'OrganizationAdmin' || role !== 'Admin',
                then: () => yup.string(),
                otherwise: () => yup.string().required(t('user_creation_field5_required_message'))
            })
        ,
        team: yup
            .string()
            .when('role', {
                is: (role) => role !== 'OrganizationAdmin' && role !== 'Admin',
                then: () => yup.string().required(t('user_creation_field6_required_message')),
                otherwise: () => yup.string() // Not required
            })
            .required(t('user_creation_field6_required_message')),
    });

    const {
        data: teamLabelsData = [],
        error: teamLabelsError,
        refetch: refetchTeamLabels,
        isUninitialized: isUninitializedTeamLabels
    } = useGetTeamLabelsQuery();
    const {
        data: organizationLabelsData = [],
        error: organizationLabelsError,
        refetch: refetchOrganizationLabels,
        isUninitialized: isUninitializedOrganizationLabels
    } = useGetOrganizationLabelsQuery(undefined, {
        skip: role !== 'Admin'
    });
    const [createUser, {error: mutationError}] = useCreateUserMutation();
    const [generalError, setGeneralError] = React.useState(null);

    const isNoneMobile = useMediaQuery("(min-width: 600px)");

    useEffect(() => {
        if (!isUninitializedTeamLabels) {
            refetchTeamLabels();
        }
    }, []);

    useEffect(() => {
        if (!isUninitializedOrganizationLabels) {
            refetchOrganizationLabels();
        }
    }, []);

    const roles = [
        {label: t('user_creation_label1'), role: 'OrganizationAdmin'},
        {label: t('user_creation_label2'), role: 'DatabaseAdmin'},
        {label: t('user_creation_label3'), role: 'User'},
    ];

    useEffect(() => {
        if (role === 'Admin') {
            roles.push({label: 'Админ Платформы', role: 'Admin'})
        }
    }, [])

    const {
        formState: {errors},
        handleSubmit,
        watch,
        control,
        reset,
    } = useForm({
        resolver: yupResolver(userSchema),
        mode: 'onSubmit',
    });

    const onSubmit = async (data) => {
        try {
            if (['OrganizationAdmin'].includes(data.role)) {
                data.team = undefined
            }

            if (['Admin'].includes(data.role)) {
                data.team = undefined
            }

            const createUserData = await createUser(data);

            if (createUserData.error) {
                console.log(`error: ${JSON.stringify(createUserData.error)}`)
                setGeneralError(createUserData.error.data.message);
            } else {
                setGeneralError(null);
                setSnackbarState({open: true, success: true, message: t('user_creation_success_toaster')});
                reset();
            }

        } catch (error) {
            console.log(`error: ${JSON.stringify(error)}`)
        }
    }

    const watchFormRoleValue = watch('role')
    const watchWholeForm = watch();

    useEffect(() => {
        setGeneralError(null)
    }, [watchWholeForm])

    const [snackbarState, setSnackbarState] = useState({
        open: false,
        success: false,
        message: '',
    });
    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarState({
            ...snackbarState,
            open: false,
        });
    };

    return (
        <Box m="20px">
            <Header title={t('user_creation_title')} subtitle={t('user_creation_subtitle')}/>
            <StyledFormBox>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Box
                        display="grid"
                        gap="30px"
                        gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                        sx={{
                            "& > div": {gridColumn: isNoneMobile ? undefined : "span 8"}
                        }}
                    >
                        <StyledFormInput
                            label={t('user_creation_input_field1_title')}
                            control={control}
                            name="fullname"
                            errors={errors.fullname}
                        />
                        <StyledFormInput
                            label={t('user_creation_input_field2_title')}
                            control={control}
                            name="email"
                            errors={errors.email}
                        />

                        <StyledMultiSelectInput
                            label={t('user_creation_input_field3_title')}
                            name='role'
                            control={control}
                            values={roles}
                            errors={errors.role}
                        />

                        <StyledFormInput
                            label={t('user_creation_input_field4_title')}
                            control={control}
                            name="password"
                            type="password"
                            errors={errors.password}
                        />
                        {
                            watchFormRoleValue && !['Admin', 'OrganizationAdmin'].includes(watchFormRoleValue) && (
                                <StyledMultiSelectInput
                                    label={t('user_creation_input_field5_title')}
                                    name='team'
                                    control={control}
                                    values={teamLabelsData}
                                    errors={errors.team}
                                />
                            )
                        }
                        {
                            ['Admin'].includes(role) && watchFormRoleValue && ['OrganizationAdmin'].includes(watchFormRoleValue) && (
                                <StyledMultiSelectInput
                                    label={t('user_creation_input_field6_title')}
                                    name='organization'
                                    control={control}
                                    values={organizationLabelsData}
                                    errors={errors.organization}
                                />
                            )
                        }
                    </Box>
                    <Box display="flex" justifyContent="end" mt="20px">
                        <Button
                            type="submit"
                            color="secondary"
                            variant="contained"
                            sx={{fontWeight: "600"}}
                        >
                            {t('user_creation_submit_button')}
                        </Button>
                    </Box>
                </form>

                <Snackbar
                    open={snackbarState.open}
                    autoHideDuration={1500}
                    onClose={handleCloseSnackbar}
                >
                    <Alert
                        severity={snackbarState.success ? "success" : "error"}
                        variant="filled"
                        sx={{width: '100%'}}
                        onClose={handleCloseSnackbar}
                    >
                        {snackbarState.message}
                    </Alert>
                </Snackbar>

                {generalError && (
                    <Typography
                        variant="h4"
                        color={colors.redAccent[500]}
                        mt="15px"
                    >
                        {generalError}
                    </Typography>
                )}
            </StyledFormBox>
        </Box>
    )

}

export default UserForm;