import React from 'react';
import {Box, Button, Typography, useMediaQuery, useTheme} from "@mui/material";
import {EmailOutlined} from "@mui/icons-material";
import {tokens} from "../../theme";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

const EmptyConversation = ({setIsCreationDialogOpen}) => {
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const matchesSmall = useMediaQuery(theme.breakpoints.down('md'));
    return (
        <Box
            sx={{
                borderRadius: "10px",
                maxWidth: "60%",
                backgroundColor: colors.aliceBlue[700],
                margin: "auto auto",
            }}
        >
            <Box
                display="flex"
                alignItems="center"
                sx={{
                    margin: "1rem auto",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "2rem",
                    padding: "1rem",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "1rem",
                    }}
                >
                    <EmailOutlined/>
                    <Typography variant={matchesSmall ? "h5" : "h4"}>
                        {t('my_chats_empty_page_title')}
                    </Typography>
                </Box>
                <Button
                    type="submit"
                    color="secondary"
                    variant="contained"
                    onClick={() => setIsCreationDialogOpen(true)}
                >
                    {t('my_chats_empty_page_button')}
                </Button>
            </Box>
        </Box>
    );
};

export default EmptyConversation;