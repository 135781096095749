const authEndpointsBuilder = (builder) => {
    return {
        login: builder.mutation({
            query: (credentials) => ({
                url: 'auth/login',
                method: 'POST',
                body: credentials
            }),
            transformResponse(baseQueryReturnValue, meta, arg) {
                return baseQueryReturnValue;
            },
            transformErrorResponse(baseQueryError, meta, arg) {
                return baseQueryError;
            },
            invalidatesTags: [
                'Conversations',
                'Messages',
                'DbConnections',
                'Organizations',
                'Teams',
                'Users',
            ],
        }),
        logout: builder.mutation({
            query: (body) => ({
                url: 'auth/logout',
                method: 'POST',
                body,
            }),
            invalidatesTags: [
                'Conversations',
                'Messages',
                'DbConnections',
                'Organizations',
                'Teams',
                'Users',
            ],
        }),
        register: builder.mutation({
            query: (credentials) => ({
                url: 'auth/register',
                method: 'POST',
                body: credentials
            })
        }),
        getMe: builder.query({
            query: () => 'auth/me'
        }),
    }
};

export default authEndpointsBuilder;