import React from 'react';
import {Outlet, useLocation} from "react-router-dom";
import Sidebar from "../scenes/global/Sidebar";
import {Box, useMediaQuery, useTheme} from "@mui/material";
import Navbar from "../scenes/global/Navbar";
import SidebarDrawer from "../scenes/global/SidebarDrawer";

function Layout(props) {
    const location = useLocation();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'nowrap',
                height: '100vh',
                alignItems: 'stretch',
                [theme.breakpoints.down('md')]: {
                    flexDirection: 'column',
                    alignItems: 'center',
                },
            }}
        >
            {location.pathname !== '/login' &&
                (matches ? (
                        <>
                            <Navbar/>
                            <SidebarDrawer/>
                        </>)
                    : <Sidebar/>)}
            <Box sx={{
                overflowX: "hidden",
                overflowY: "auto",
                width: "100vw",
            }}>
                <Outlet/>
            </Box>
        </Box>
    );
}

export default Layout;