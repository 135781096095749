import {createSlice} from "@reduxjs/toolkit";

const applicationSlice = createSlice({
    name: 'application',
    initialState: {
        isConversationSidebarOpen: true,
        isSidebarOpen: true,
        isDrawerOpen: false,
        isConversationsDrawerOpen: false,
        language: 'ru',
    },
    reducers: {
        setIsConversationSidebarOpen: (state) => {
            state.isConversationSidebarOpen = !state.isConversationSidebarOpen
        },
        setIsSidebarOpen: (state) => {
            state.isSidebarOpen = !state.isSidebarOpen
        },
        setIsDrawerOpen: (state) => {
            state.isDrawerOpen = !state.isDrawerOpen
        },
        setIsConversationsDrawerOpen: (state) => {
            state.isConversationsDrawerOpen = !state.isConversationsDrawerOpen
        },
        setLanguage: (state, action) => {
            state.language = action.payload
        },
    },
});


export default applicationSlice.reducer;
export const {
    setIsConversationSidebarOpen,
    setIsSidebarOpen,
    setIsDrawerOpen,
    setIsConversationsDrawerOpen,
    setLanguage,
} = applicationSlice.actions;