import {useContext, useEffect} from 'react';
import 'react-pro-sidebar/dist/css/styles.css';
import {Box, IconButton, Typography, useTheme} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {ColorModeContext, tokens} from "../../theme";
import {
    ApartmentOutlined,
    DarkModeOutlined,
    GroupAddOutlined,
    HubOutlined,
    LightModeOutlined,
    LogoutOutlined,
    MenuOutlined,
    PeopleOutlined,
    PersonAddAltOutlined,
    PersonOutlined,
    QuestionAnswerOutlined,
    LanguageOutlined
} from "@mui/icons-material";
import {Menu, MenuItem, ProSidebar} from "react-pro-sidebar";
import SidebarItem from "./SidebarItem";
import {logout as authLogout} from "../../store/authSlice/authSlice";
import {useDispatch, useSelector} from "react-redux";
import {api, useLogoutMutation} from "../../store/emptySplitApi";
import {persistor} from "../../store/index.tsx";
import SidebarSectionTitle from "./components/SidebarSectionTitle.jsx";
import {setIsSidebarOpen, setLanguage} from "../../store/applicationSlice/applicationSlice";
import {ROUTES} from "../../App";
import {ROLES} from "../../util/constants";
import {useTranslation} from "react-i18next";
import axios from "axios";

const Sidebar = () => {
    let language = useSelector(state => state.application.language);
    const {t, i18n} = useTranslation();

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const isSidebarOpen = useSelector(state => state.application.isSidebarOpen);

    const [logout] = useLogoutMutation();
    const colorMode = useContext(ColorModeContext);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const accessToken = useSelector(state => state.auth?.user?.accessToken);
    const userId = useSelector(state => state.auth?.user?.user?.id);
    const role = useSelector(state => state.auth?.user?.user?.role);

    const onLogout = async () => {
        try {
            await logout({accessToken, userId}).unwrap();

            dispatch(api.util.resetApiState());
            dispatch(authLogout());
            persistor.purge()
                .then(() => {
                    navigate('/login');
                })
                .catch(error => {
                    console.error('Persistor purge error:', error);
                });
        } catch (error) {
            console.log(`error`)
            console.log(error);
        }
    };

    return (
        <Box
            sx={{
                "& .pro-sidebar-inner": {
                    background: `${colors.primary[400]} !important`
                },
                "& .pro-icon-wrapper": {
                    backgroundColor: "transparent !important"
                },
                "& .pro-inner-item": {
                    color: colors.grey[100],
                    fontWeight: "500",
                },
                "& .pro-inner-item:hover": {
                    color: "#4cceac !important",
                },
                "& .pro-menu-item.active": {
                    color: "#4cceac !important",
                },
                "& .pro-menu-item": {
                    padding: "0.2rem 0 0 0"
                },
            }}
        >
            <ProSidebar collapsed={isSidebarOpen}>
                {/*<ProSidebar>*/}
                <Menu iconShape="square">
                    {/* LOGO AND MENU ICON */}
                    <MenuItem
                        onClick={isSidebarOpen ? () => dispatch(setIsSidebarOpen()) : undefined}
                        icon={isSidebarOpen ? <MenuOutlined/> : undefined}
                        style={{
                            margin: "10px 0 20px 0",
                            // color: colors.grey[100],
                        }}
                    >
                        {!isSidebarOpen && (
                            <Box
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                ml="15px"
                            >
                                <Typography
                                    variant="h3"
                                    color={colors.grey[100]}
                                    onClick={() => navigate('/')}
                                >
                                    STRELLA
                                </Typography>

                                <IconButton onClick={() => dispatch(setIsSidebarOpen())}>
                                    <MenuOutlined/>
                                </IconButton>
                            </Box>
                        )}
                    </MenuItem>

                    {/*    Menu items   */}
                    <Box paddingLeft={!isSidebarOpen ? undefined : '10%'}>
                        <SidebarItem
                            title={t('sidebar_my_chats')}
                            to={ROUTES.CONVERSATIONS}
                            icon={<QuestionAnswerOutlined/>}
                            isSidebarOpen={!isSidebarOpen}
                        />
                        {
                            role && [ROLES.ADMIN, ROLES.ORGANIZATION_ADMIN, ROLES.DATABASE_ADMIN].includes(role) && (
                                <SidebarSectionTitle title={t('sidebar_split_management')} isSidebarOpen={isSidebarOpen}/>
                            )
                        }

                        {
                            role && [ROLES.ADMIN].includes(role) && (
                                <>
                                    <SidebarItem
                                        title={t('sidebar_organization_management')}
                                        to={ROUTES.ORGANIZATIONS_TABLE}
                                        icon={<HubOutlined/>}
                                        isSidebarOpen={!isSidebarOpen}
                                    />

                                    <SidebarItem
                                        title={t('sidebar_create_organization')}
                                        to={ROUTES.ORGANIZATIONS_FORM}
                                        icon={<ApartmentOutlined/>}
                                        isSidebarOpen={!isSidebarOpen}
                                    />
                                </>
                            )
                        }

                        {
                            role && [ROLES.ADMIN, ROLES.ORGANIZATION_ADMIN].includes(role) && (
                                <>
                                    <SidebarItem
                                        title={t('sidebar_teams_management')}
                                        to={ROUTES.TEAM_TABLE}
                                        icon={<PeopleOutlined/>}
                                        isSidebarOpen={!isSidebarOpen}
                                    />

                                    <SidebarItem
                                        title={t('sidebar_create_team')}
                                        to={ROUTES.TEAM_FORM}
                                        icon={<GroupAddOutlined/>}
                                        isSidebarOpen={!isSidebarOpen}
                                    />

                                    <SidebarItem
                                        title={t('sidebar_users_management')}
                                        to={ROUTES.USER_TABLE}
                                        icon={<PersonOutlined/>}
                                        isSidebarOpen={!isSidebarOpen}
                                    />

                                    <SidebarItem
                                        title={t('sidebar_create_user')}
                                        to={ROUTES.USER_FORM}
                                        icon={<PersonAddAltOutlined/>}
                                        isSidebarOpen={!isSidebarOpen}
                                    />
                                </>
                            )
                        }

                        {
                            role && [ROLES.ADMIN, ROLES.ORGANIZATION_ADMIN, ROLES.DATABASE_ADMIN].includes(role) && (
                                <>
                                    <SidebarItem
                                        title={t('sidebar_database_connections_management')}
                                        to={ROUTES.DB_CONNECTION_TABLE}
                                        icon={<GroupAddOutlined/>}
                                        isSidebarOpen={!isSidebarOpen}
                                    />

                                    <SidebarItem
                                        title={t('sidebar_create_database_connection')}
                                        to={ROUTES.DB_CONNECTION_FORM}
                                        icon={<GroupAddOutlined/>}
                                        isSidebarOpen={!isSidebarOpen}
                                    />
                                </>
                            )
                        }


                        <SidebarSectionTitle title={t('sidebar_split_general')} isSidebarOpen={isSidebarOpen}/>

                        {/*<SidebarItem*/}
                        {/*    title="Профиль"*/}
                        {/*    to="/profile"*/}
                        {/*    icon={<AccountCircleOutlined/>}*/}
                        {/*    isSidebarOpen={!isSidebarOpen}*/}
                        {/*/>*/}

                        {/*<SidebarItem*/}
                        {/*    title="FAQ"*/}
                        {/*    to="/faq"*/}
                        {/*    icon={<HelpOutlined/>}*/}
                        {/*    isSidebarOpen={!isSidebarOpen}*/}
                        {/*/>*/}

                        <SidebarItem
                            title={t('sidebar_logout')}
                            icon={<LogoutOutlined/>}
                            onClickFunction={onLogout}
                            isSidebarOpen={!isSidebarOpen}
                        />

                        {theme.palette.mode === 'dark' ? (
                            <SidebarItem
                                title={t("sidebar_lite_mode")}
                                icon={<LightModeOutlined/>}
                                onClickFunction={colorMode.toggleColorMode}
                                isSidebarOpen={!isSidebarOpen}
                            />
                        ) : (
                            <SidebarItem
                                title={t('sidebar_dark_mode')}
                                icon={<DarkModeOutlined/>}
                                onClickFunction={colorMode.toggleColorMode}
                                isSidebarOpen={!isSidebarOpen}
                            />
                        )}
                        <SidebarItem
                            title={language === 'en' ? 'RUS' : 'ENG'}
                            icon={<LanguageOutlined/>}
                            onClickFunction={() => {
                                dispatch(setLanguage(language === 'en' ? 'ru' : 'en'))
                                i18n.changeLanguage(language === 'en' ? 'ru' : 'en')
                            }}
                            isSidebarOpen={!isSidebarOpen}
                        />
                    </Box>
                </Menu>
            </ProSidebar>

        </Box>
    );
}

export default Sidebar;