import {
    Divider,
    Drawer,
    IconButton,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Typography,
    useTheme
} from "@mui/material";
import {setIsDrawerOpen, setLanguage} from "../../../store/applicationSlice/applicationSlice";
import {
    ApartmentOutlined,
    Close,
    GroupAddOutlined,
    HubOutlined,
    PeopleOutlined,
    PersonAddAltOutlined,
    PersonOutlined,
    QuestionAnswerOutlined
} from "@mui/icons-material";
import {useDispatch, useSelector} from "react-redux";
import {NavLink, useNavigate} from "react-router-dom";
import {ROUTES} from "../../../App";
import {persistor} from "../../../store/index.tsx";
import {useLogoutMutation} from "../../../store/emptySplitApi";
import {logout as authLogout} from "../../../store/authSlice/authSlice";
import React, {useContext} from "react";
import {ColorModeContext, tokens} from "../../../theme";
import {ROLES} from "../../../util/constants";
import {useTranslation} from "react-i18next";

const SidebarDrawer = () => {
    let language = useSelector(state => state.application.language);
    const {t, i18n} = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const isDrawerOpen = useSelector(state => state.application.isDrawerOpen);
    const accessToken = useSelector(state => state.auth?.user?.accessToken);
    const userId = useSelector(state => state.auth?.user?.user?.id);
    const [logout] = useLogoutMutation();

    const role = useSelector(state => state.auth?.user?.user?.role);

    const colorMode = useContext(ColorModeContext);

    const onLogout = async () => {
        try {
            await logout({accessToken, userId}).unwrap();

            dispatch(authLogout());
            persistor.purge()
                .then(() => {
                    navigate('/login');
                })
                .catch(error => {
                    console.error('Persistor purge error:', error);
                });

            dispatch(setIsDrawerOpen())
        } catch (error) {
            console.log(`error`)
            console.log(error);
        }
    };

    return (
        <>
            {isDrawerOpen && (
                <IconButton
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: '70%',
                        zIndex: 1999
                    }}
                    onClick={() => dispatch(setIsDrawerOpen())}>
                    <Close sx={{fontSize: '2.5rem'}}/>
                </IconButton>
            )}
            <Drawer
                open={isDrawerOpen}
                sx={{
                    "& .MuiDrawer-paper": {
                        width: '70%',
                    },
                    "& .MuiListItemButton-root": {
                        width: '100%',
                    }
                }}
            >
                <List>
                    <ListItemButton
                        onClick={() => dispatch(setIsDrawerOpen())}
                    >
                        <ListItemIcon>
                            {<QuestionAnswerOutlined/>}
                        </ListItemIcon>
                        <NavLink to={ROUTES.CONVERSATIONS} style={{width: '100%'}}>
                            <ListItemText>{t('sidebar_my_chats')}</ListItemText>
                        </NavLink>
                    </ListItemButton>
                    <Divider variant="middle"/>
                    {
                        role && [ROLES.ADMIN, ROLES.ORGANIZATION_ADMIN, ROLES.DATABASE_ADMIN].includes(role) && (
                            <>
                                <Typography
                                    noWrap={true}
                                    variaht="h6"
                                    color={colors.grey[300]}
                                    sx={{m: "1rem 0 0.3rem 1rem"}}
                                >
                                    {t('sidebar_split_management')}
                                </Typography>
                                <Divider variant="middle"/>
                            </>
                        )
                    }

                    {
                        role && [ROLES.ADMIN].includes(role) && (
                            <>
                                <ListItemButton
                                    onClick={() => dispatch(setIsDrawerOpen())}
                                >
                                    <ListItemIcon>
                                        <HubOutlined/>
                                    </ListItemIcon>
                                    <NavLink to={ROUTES.ORGANIZATIONS_TABLE} style={{width: '100%'}}>
                                        <ListItemText>{t('sidebar_organization_management')}</ListItemText>
                                    </NavLink>
                                </ListItemButton>
                                <Divider variant="middle"/>

                                <ListItemButton
                                    onClick={() => dispatch(setIsDrawerOpen())}
                                >
                                    <ListItemIcon>
                                        <ApartmentOutlined/>
                                    </ListItemIcon>
                                    <NavLink to={ROUTES.ORGANIZATIONS_FORM} style={{width: '100%'}}>
                                        <ListItemText>{t('sidebar_create_organization')}</ListItemText>
                                    </NavLink>
                                </ListItemButton>
                                <Divider variant="middle"/>
                            </>
                        )
                    }

                    {
                        role && [ROLES.ADMIN, ROLES.ORGANIZATION_ADMIN].includes(role) && (
                            <>
                                <ListItemButton
                                    onClick={() => dispatch(setIsDrawerOpen())}
                                >
                                    <ListItemIcon>
                                        <PeopleOutlined/>
                                    </ListItemIcon>
                                    <NavLink to={ROUTES.TEAM_TABLE} style={{width: '100%'}}>
                                        <ListItemText>{t('sidebar_teams_management')}</ListItemText>
                                    </NavLink>
                                </ListItemButton>
                                <Divider variant="middle"/>

                                <ListItemButton
                                    onClick={() => dispatch(setIsDrawerOpen())}
                                >
                                    <ListItemIcon>
                                        <GroupAddOutlined/>
                                    </ListItemIcon>
                                    <NavLink to={ROUTES.TEAM_FORM} style={{width: '100%'}}>
                                        <ListItemText>{t('sidebar_create_team')}</ListItemText>
                                    </NavLink>
                                </ListItemButton>
                                <Divider variant="middle"/>

                                <ListItemButton
                                    onClick={() => dispatch(setIsDrawerOpen())}
                                >
                                    <ListItemIcon>
                                        <PersonOutlined/>
                                    </ListItemIcon>
                                    <NavLink to={ROUTES.USER_TABLE} style={{width: '100%'}}>
                                        <ListItemText>{t('sidebar_users_management')}</ListItemText>
                                    </NavLink>
                                </ListItemButton>
                                <Divider variant="middle"/>

                                <ListItemButton
                                    onClick={() => dispatch(setIsDrawerOpen())}
                                >
                                    <ListItemIcon>
                                        <PersonAddAltOutlined/>
                                    </ListItemIcon>
                                    <NavLink to={ROUTES.USER_FORM} style={{width: '100%'}}>
                                        <ListItemText>{t('sidebar_create_user')}</ListItemText>
                                    </NavLink>
                                </ListItemButton>
                                <Divider variant="middle"/>
                            </>
                        )
                    }
                    {
                        role && [ROLES.ADMIN, ROLES.ORGANIZATION_ADMIN, ROLES.DATABASE_ADMIN].includes(role) && (
                            <>
                                <ListItemButton
                                    onClick={() => dispatch(setIsDrawerOpen())}
                                >
                                    <ListItemIcon>
                                        <GroupAddOutlined/>
                                    </ListItemIcon>
                                    <NavLink to={ROUTES.DB_CONNECTION_TABLE} style={{width: '100%'}}>
                                        <ListItemText>{t('sidebar_database_connections_management')}</ListItemText>
                                    </NavLink>
                                </ListItemButton>
                                <Divider variant="middle"/>

                                <ListItemButton
                                    onClick={() => dispatch(setIsDrawerOpen())}
                                >
                                    <ListItemIcon>
                                        <GroupAddOutlined/>
                                    </ListItemIcon>
                                    <NavLink to={ROUTES.DB_CONNECTION_FORM} style={{width: '100%'}}>
                                        <ListItemText>{t('sidebar_create_database_connection')}</ListItemText>
                                    </NavLink>
                                </ListItemButton>
                                <Divider variant="middle"/>
                            </>
                        )
                    }

                    <Typography
                        noWrap={true}
                        variaht="h6"
                        color={colors.grey[300]}
                        sx={{m: "2rem 0 0.3rem 1rem"}}
                    >
                        {t('sidebar_split_general')}
                    </Typography>
                    <Divider variant="middle"/>

                    <ListItemButton
                        onClick={onLogout}
                    >
                        <ListItemText>{t('sidebar_logout')}</ListItemText>
                    </ListItemButton>
                    <Divider variant="middle"/>

                    {theme.palette.mode === 'dark' ? (
                        <ListItemButton
                            onClick={colorMode.toggleColorMode}
                        >
                            <ListItemText>{t('sidebar_lite_mode')}</ListItemText>
                        </ListItemButton>
                    ) : (
                        <ListItemButton
                            onClick={colorMode.toggleColorMode}
                        >
                            <ListItemText>{t('sidebar_dark_mode')}</ListItemText>
                        </ListItemButton>
                    )}
                    <ListItemButton
                        onClick={() => {
                            dispatch(setLanguage(language === 'en' ? 'ru' : 'en'))
                            i18n.changeLanguage(language === 'en' ? 'ru' : 'en')
                        }}
                    >
                        <ListItemText>{language === 'en' ? 'RUS' : 'ENG'}</ListItemText>
                    </ListItemButton>

                </List>
            </Drawer>
        </>
    );
};

export default SidebarDrawer;