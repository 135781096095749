import {Alert, Box, Button, Snackbar, Typography, useMediaQuery, useTheme} from "@mui/material";
import * as yup from "yup";
import Header from "../../components/Header";
import {tokens} from "../../theme";
import {useCreateOrganizationMutation} from "../../store/emptySplitApi";
import React, {useMemo, useState} from "react";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import StyledFormInput from "../../components/StyledFormInput";
import StyledFormBox from "../../components/StyledFormBox";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

const OrganizationForm = () => {
    const {t, i18n} = useTranslation();

    const organizationSchema = yup.object().shape({
        name: yup.string().min(3).required(t('organization_creation_field1_required_message')),
    });

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [createOrganization, {error: mutationError}] = useCreateOrganizationMutation();
    const [generalError, setGeneralError] = React.useState(null);

    const isNoneMobile = useMediaQuery("(min-width: 600px)");

    const {
        formState: {errors},
        handleSubmit,
        control,
        reset,
    } = useForm({
        resolver: yupResolver(organizationSchema),
        defaultValues: {
            name: "",
        },
        mode: 'onSubmit',
    });

    const onSubmit = async (data) => {
        try {
            const createOrganizationData = await createOrganization(data);
            if (createOrganizationData.error) {
                console.log(`error: ${JSON.stringify(createOrganizationData.error)}`)
                setGeneralError(createOrganizationData.error.data.message);
            } else {
                setGeneralError(null);
                setSnackbarState({open: true, success: true, message: t('organization_creation_success_toaster')});
                reset();
            }

        } catch (error) {
            console.log(`error: ${JSON.stringify(error)}`)
        }
    }

    const [snackbarState, setSnackbarState] = useState({
        open: false,
        success: false,
        message: '',
    });
    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarState({
            ...snackbarState,
            open: false,
        });
    };

    return (
        <Box m="20px">
            <Header title={t('organization_creation_title')} subtitle={t('organization_creation_subtitle')}/>
            <StyledFormBox>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Box
                        display="grid"
                        gap="30px"
                        gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                        sx={{
                            "& > div": {gridColumn: isNoneMobile ? undefined : "span 8"}
                        }}
                    >
                        <StyledFormInput
                            label={t('organization_creation_title')}
                            name="name"
                            control={control}
                            errors={errors.name}
                        />
                    </Box>

                    <Box display="flex" justifyContent="end" mt="20px">
                        <Button
                            type="submit"
                            color="secondary"
                            variant="contained"
                            sx={{fontWeight: "600"}}
                        >
                            {t('organization_creation_submit_button')}
                        </Button>
                    </Box>
                </form>
                <Snackbar
                    open={snackbarState.open}
                    autoHideDuration={1500}
                    onClose={handleCloseSnackbar}
                >
                    <Alert
                        severity={snackbarState.success ? "success" : "error"}
                        variant="filled"
                        sx={{width: '100%'}}
                        onClose={handleCloseSnackbar}
                    >
                        {snackbarState.message}
                    </Alert>
                </Snackbar>
                {generalError && (
                    <Typography
                        variant="h4"
                        color={colors.redAccent[500]}
                        mt="15px"
                    >
                        {generalError}
                    </Typography>
                )}
            </StyledFormBox>
        </Box>
    )

}

export default OrganizationForm;