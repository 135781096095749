import userEndpointsBuilder from "../userSlice/userApi";

const organizationEndpointsBuilder = (builder) => {
    return {
        getOrganization: builder.query({
            query: (id) => (
                {
                    url: `organization/${id}`,
                    method: 'GET',
                }
            ),
            transformResponse(baseQueryReturnValue, meta, arg) {
                return baseQueryReturnValue;
            },
            transformErrorResponse(baseQueryError, meta, arg) {
                return baseQueryError;
            },

        }),
        getOrganizations: builder.query({
            query: () => ({
                url: 'organization',
                method: 'GET',
            }),
            providesTags: (result) =>
                result
                    ? [...result.map(({ id }) => ({ type: 'Organizations', id })), 'Organizations']
                    : ['Organizations'],
            transformResponse(baseQueryReturnValue, meta, arg) {
                return baseQueryReturnValue;
            },
        }),
        getOrganizationLabels: builder.query({
            query: () => ({
                url: 'organization',
                method: 'GET',
            }),
            providesTags: (result) =>
                result
                    ? [...result.map(({ id }) => ({ type: 'Organizations', id })), 'Organizations']
                    : ['Organizations'],
            transformResponse(baseQueryReturnValue, meta, arg) {
                return baseQueryReturnValue.map(({_id, name}) => ({label: name, organization: _id}));
            },
        }),
        createOrganization: builder.mutation({
            query: (body) => ({
                url: 'organization',
                method: 'POST',
                body
            }),
            invalidatesTags: ['Organizations'],
            transformResponse(baseQueryReturnValue, meta, arg) {
                return baseQueryReturnValue;
            },
            transformErrorResponse(baseQueryError, meta, arg) {
                return baseQueryError;
            }
        }),
        updateOrganization: builder.mutation({
            query: ({id, body}) => ({
                url: `organization/${id}`,
                method: 'PUT',
                body
            }),
            invalidatesTags: ['Organizations']
        }),
        deleteOrganization: builder.mutation({
            query: (id) => ({
                url: `organization/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Organizations']
        }),
    }
};

export default organizationEndpointsBuilder;
