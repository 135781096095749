import {Box, Typography} from "@mui/material";

const Page404 = (props) => {
    return (
        <Box
            sx={{
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Typography variant="h3">
                Упс. Запрашиваемая страница не найдена!
            </Typography>
        </Box>
    )
};

export default Page404;