import {useDispatch} from "react-redux";
import {Box, Button, IconButton, Typography, useTheme} from "@mui/material";
import {useMediaQuery} from "@mui/material";
import {Menu, Checklist} from '@mui/icons-material';
import {setIsConversationsDrawerOpen, setIsDrawerOpen} from "../../../store/applicationSlice/applicationSlice";
import {tokens} from "../../../theme";
import {useLocation, useNavigate} from "react-router-dom";
import {ROUTES} from "../../../App";

const Navbar = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const location = useLocation();
    const navigate = useNavigate();

    const dispatch = useDispatch();

    const smallMatches = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '15px 20px',
                width: '100%',
                background: colors.primary[400],
            }}
        >
            <Typography
                variant="h3"
                color={colors.grey[100]}
                onClick={() => navigate('/')}
            >
                STRELLA
            </Typography>
            {
                location.pathname.includes(ROUTES.CONVERSATIONS)
                    ? smallMatches
                        ? (
                            <IconButton onClick={() => dispatch(setIsConversationsDrawerOpen())}>
                                <Checklist/>
                            </IconButton>
                        ) : (
                            <Button
                                onClick={() => dispatch(setIsConversationsDrawerOpen())}
                                startIcon={<Checklist/>}
                                sx={{
                                    color: '#FAFAFA',
                                    fontSize: '16px',
                                }}
                            >
                                Список чатов
                            </Button>
                        )
                    : null
            }
            {
                smallMatches
                    ? (
                        <IconButton onClick={() => dispatch(setIsDrawerOpen())}>
                            <Menu/>
                        </IconButton>
                    ) : (
                        <Button
                            onClick={() => dispatch(setIsDrawerOpen())}
                            startIcon={<Menu/>}
                            sx={{
                                color: '#FAFAFA',
                                fontSize: '16px',
                            }}
                        >
                            Навигация
                        </Button>
                    )
            }
        </Box>
    );
};

export default Navbar;