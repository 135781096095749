import {Box, Button, Typography, useMediaQuery, useTheme} from "@mui/material";
import * as yup from "yup";
import React from "react";
import {useGetDbConnectionLabelsQuery, useStartConversationMutation} from "../../store/emptySplitApi";
import {tokens} from "../../theme";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import StyledFormInput from "../../components/StyledFormInput";
import {useNavigate} from "react-router-dom";
import StyledMultiSelectInput from "../../components/StyledMultiSelectInput";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

const userSchema = yup.object().shape({
    title: yup.string().required("Название чата обязательное поле"),
    dbConnection: yup.string().required("БД подключение обязательное поле"),
});

const ConversationForm = ({handleCreationDialogClose}) => {
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const navigate = useNavigate();

    const {data: dbConnectionLabelsData = [], error: teamLabelsError} = useGetDbConnectionLabelsQuery(undefined, {
        pollingInterval: 3000,
    });
    const [startConversation, {error: mutationError}] = useStartConversationMutation();
    const [generalError, setGeneralError] = React.useState(null);
    const [message, setMessage] = React.useState(null);

    const isNoneMobile = useMediaQuery("(min-width: 600px)");

    const matchesSmall = useMediaQuery(theme.breakpoints.down('md'));

    const {
        formState: {errors},
        handleSubmit,
        control,
        reset,
    } = useForm({
        resolver: yupResolver(userSchema),
        defaultValues: {
            title: "",
            dbConnection: "",
        },
        mode: 'onSubmit',
    });

    const onSubmit = async (data) => {
        try {
            const startConversationData = await startConversation(data);
            if (startConversationData.error) {
                console.log(`error: ${JSON.stringify(startConversationData.error)}`)
                setGeneralError(startConversationData.error.data.message);
                setMessage(null);
            } else {
                setGeneralError(null);
                setMessage(t('my_chats_creation_popup_create_success_toaster'));
                handleCreationDialogClose();
                navigate(`/conversations/${startConversationData.data._id}`);
                reset();
            }

        } catch (error) {
            console.log(`error: ${JSON.stringify(error)}`)
        }
    }

    return (
        <Box m="20px">
            <Box
                p={5}
                borderRadius={5}
                sx={{
                    "& .MuiInputLabel-root": {},
                    "& .MuiInputLabel-formControl": {
                        color: `${colors.primary[100]}`,
                    },
                    "& .MuiOutlinedInput-root": {
                        borderRadius: "10px !important",
                    },
                    "& .MuiOutlinedInput-input:-webkit-autofill": {
                        WebkitBoxShadow: "0 0 0 100px #96cae8 inset",
                        WebkitTextFillColor: "#FAFAFA !important",
                    },
                    padding: "30px 40px"
                }}
            >

                <form onSubmit={handleSubmit(onSubmit)}>
                    <Box
                        display="grid"
                        gap="30px"
                        gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                        sx={{
                            "& > div": {gridColumn: isNoneMobile ? undefined : "span 8"},
                            "& .MuiAutocomplete-listbox": {
                                maxHeight: "8rem !important",
                            },
                        }}
                    >
                        <StyledFormInput
                            label={t('my_chats_creation_popup_title_input')}
                            name="title"
                            control={control}
                            errors={errors.title}
                        />

                        <StyledMultiSelectInput
                            label={t('my_chats_creation_popup_db_connection_input')}
                            name='dbConnection'
                            control={control}
                            values={dbConnectionLabelsData}
                            errors={errors.team}
                        />

                    </Box>
                    <Box

                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            mt: "20px",
                            [theme.breakpoints.down('md')]: {
                                flexDirection: 'column',
                                justifyContent: 'stretch',
                                gap: '0.5rem'
                            }
                        }}
                    >
                        {
                            matchesSmall ? (
                                <>
                                    <Button type="submit" color="secondary" variant="contained">
                                        {t('my_chats_creation_popup_create_button')}
                                    </Button>
                                    <Button onClick={handleCreationDialogClose} color="secondary" variant="contained">
                                        {t('my_chats_creation_popup_cancel_button')}
                                    </Button>
                                </>
                            ) : (
                                <>
                                    <Button onClick={handleCreationDialogClose} color="secondary" variant="contained">
                                        {t('my_chats_creation_popup_cancel_button')}
                                    </Button>
                                    <Button type="submit" color="secondary" variant="contained">
                                        {t('my_chats_creation_popup_create_button')}
                                    </Button>
                                </>
                            )
                        }
                    </Box>
                </form>
                {message && (
                    <Typography
                        variant="h4"
                        color={colors.greenAccent[500]}
                        mt="15px"
                    >
                        {message}
                    </Typography>
                )}
                {generalError && (
                    <Typography
                        variant="h4"
                        color={colors.redAccent[500]}
                        mt="15px"
                    >
                        {generalError}
                    </Typography>
                )}
            </Box>
        </Box>
    )

}

export default ConversationForm;