import {Typography, useTheme} from "@mui/material";
import {tokens} from "../../../theme";
import Divider from '@mui/material/Divider';

const SidebarSectionTitle = ({title, isSidebarOpen}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    if (isSidebarOpen) return (
        <Divider sx={{m: "1rem 0 1.6rem 0"}}/>
    );

    return (
        <Typography
            noWrap={true}
            variaht="h6"
            color={colors.grey[300]}
            sx={{m: "1rem 0 0.3rem 1.5rem"}}
        >
            {title}
        </Typography>
    )
};

export default SidebarSectionTitle;