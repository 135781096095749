import React, {useEffect, useRef} from 'react';
import {Box, useMediaQuery, useTheme} from "@mui/material";
import {useGetConversationMessagesQuery} from "../../store/emptySplitApi";
import UserMessageComponent from './MessageComponents/UserMessageComponent'; // Assuming you have this component
import SystemMessageComponent from './MessageComponents/SystemMessageComponent'; // New component for system messages
import ErrorMessageComponent from './MessageComponents/ErrorMessageComponent'; // New component for error messages
import ModelUncertaintyMessageComponent from './MessageComponents/ModelUncertaintyMessageComponent'; // New component for model uncertainty messages
import {tokens} from "../../theme";
import {useParams} from "react-router-dom";

const ConversationMessages = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const {conversationId} = useParams();
    const {data: messagesData = [], isLoading, refetch, isUninitialized} = useGetConversationMessagesQuery(conversationId, {
        pollingInterval: 1000,
        refetchOnFocus: true,
        refetchOnReconnect: true,
        skip: !conversationId,
    });

    const matches = useMediaQuery(theme.breakpoints.down('md'));

    useEffect(() => {
        if (!isUninitialized) {
            refetch();
        }
    }, []);

    const messagesEndRef = useRef(null);

    useEffect(() => {
        messagesEndRef.current?.scrollIntoView({behavior: 'smooth'});
    }, [messagesData, isLoading]);

    const renderMessage = (message) => {
        switch (message.type) {
            case 'UserMessage':
                return <UserMessageComponent message={message}/>;
            case 'SystemMessage':
                return <SystemMessageComponent message={message}/>;
            case 'ErrorMessage':
                return <ErrorMessageComponent message={message}/>;
            case 'ModelUncertaintyMessage':
                return <ModelUncertaintyMessageComponent message={message}/>;
            default:
                return null; // Or a default message component
        }
    };

    return (
        <Box

            sx={{
                overflowY: 'auto',
                display: "flex",
                flexDirection: "column",
                alignItems: "stretch",
                backgroundColor: "#FAFAFA",
                color: "black",
                width: matches ? '100vw' : undefined,
            }}
        >
            {messagesData?.map((message) => (
                <Box
                    display="flex"
                    flexDirection="column"
                    mb="10px"
                    p={2}
                    width={1}
                    key={message._id}
                    borderBottom={`0.1px solid ${colors.grey[100]}`}
                >
                    {renderMessage(message)}
                </Box>
            ))}
            <div ref={messagesEndRef}/>
        </Box>
    );
};

export default ConversationMessages;