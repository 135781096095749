import {ColorModeContext, useMode} from './theme';
import {CssBaseline, ThemeProvider} from "@mui/material";
import {Route, Routes} from "react-router-dom";
import UserForm from "./scenes/userForm";
import UserTable from "./scenes/userTable";
import TeamTable from "./scenes/teamTable";
import TeamForm from "./scenes/teamForm";
import Conversations from "./scenes/conversations";
import DbConnectionForm from "./scenes/dbConnectionForm";
import DbConnectionTable from "./scenes/dbConnectionTable";
import Login from "./scenes/login/Login.jsx";
import RequireAuth from "./hoc/RequireAuth";
import Layout from "./components/Layout";
import GreetingPage from "./scenes/greetingPage";
import {
    ApartmentOutlined,
    GroupAddOutlined,
    HubOutlined,
    PeopleOutlined,
    PersonAddAltOutlined,
    PersonOutlined,
    QuestionAnswerOutlined
} from "@mui/icons-material";
import OrganizationTable from "./scenes/organizationTable";
import OrganizationForm from "./scenes/organiazationForm";
import Page404 from "./scenes/global/Page404";
import RequireRole from "./hoc/RequireRole";
import {ROLES} from "./util/constants";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {useEffect} from "react";

export const ROUTES = {
    CONVERSATIONS: "/conversations",
    USER_FORM: "/user-form",
    USER_TABLE: "/user-table",
    ORGANIZATIONS_TABLE: "/organization-table",
    ORGANIZATIONS_FORM: "/organization-form",
    TEAM_TABLE: "/team-table",
    TEAM_FORM: "/team-form",
    DB_CONNECTION_TABLE: "/db-connection-table",
    DB_CONNECTION_FORM: "/db-connection-form",
    LOGIN: "/login",
    NOT_FOUND: "/not-found"
}

export const SIDEBAR_ITEMS = [
    {
        route: ROUTES.CONVERSATIONS,
        title: "sidebar_my_chats",
        icon: <QuestionAnswerOutlined/>,
    },
    {
        route: ROUTES.ORGANIZATIONS_TABLE,
        title: "sidebar_organization_management",
        icon: <HubOutlined/>
    },
    {
        route: ROUTES.ORGANIZATIONS_FORM,
        title: "sidebar_create_organization",
        icon: <ApartmentOutlined/>
    },
    {
        route: ROUTES.TEAM_TABLE,
        title: "sidebar_teams_management",
        icon: <PeopleOutlined/>
    },
    {
        route: ROUTES.TEAM_FORM,
        title: "sidebar_create_team",
        icon: <GroupAddOutlined/>
    },
    {
        route: ROUTES.DB_CONNECTION_TABLE,
        title: "sidebar_database_connections_management",
        icon: <GroupAddOutlined/>
    },
    {
        route: ROUTES.DB_CONNECTION_FORM,
        title: "sidebar_create_database_connection",
        icon: <GroupAddOutlined/>
    },
    {
        route: ROUTES.USER_TABLE,
        title: "sidebar_users_management",
        icon: <PersonOutlined/>
    },
    {
        route: ROUTES.USER_FORM,
        title: "sidebar_create_user",
        icon: <PersonAddAltOutlined/>,
    },
];

function App() {
    const [theme, colorMode] = useMode();
    const {t, i18n} = useTranslation()
    const language = useSelector(state => state.application.language);

    useEffect(() => {
        i18n.changeLanguage(language)
    }, []);

    return (
        <ColorModeContext.Provider value={colorMode}>
            <ThemeProvider theme={theme}>
                <CssBaseline/>
                <div>
                    <Routes>
                        <Route path="/" element={<Layout/>}>
                            <Route index element={
                                <RequireAuth>
                                    <GreetingPage/>
                                </RequireAuth>
                            }/>
                            <Route path={ROUTES.CONVERSATIONS} element={
                                <RequireAuth>
                                    <Conversations/>
                                </RequireAuth>
                            }/>
                            <Route path={`${ROUTES.CONVERSATIONS}/:conversationId`} element={
                                <RequireAuth>
                                    <Conversations/>
                                </RequireAuth>
                            }/>
                            <Route path={ROUTES.ORGANIZATIONS_TABLE} element={
                                <RequireAuth>
                                    <RequireRole leastAllowedRole={ROLES.ADMIN}>
                                        <OrganizationTable/>
                                    </RequireRole>
                                </RequireAuth>
                            }/>
                            <Route path={ROUTES.ORGANIZATIONS_FORM} element={
                                <RequireAuth>
                                    <RequireRole leastAllowedRole={ROLES.ADMIN}>
                                        <OrganizationForm/>
                                    </RequireRole>
                                </RequireAuth>
                            }/>
                            <Route path={ROUTES.TEAM_TABLE} element={
                                <RequireAuth>
                                    <RequireRole leastAllowedRole={ROLES.ORGANIZATION_ADMIN}>
                                        <TeamTable/>
                                    </RequireRole>
                                </RequireAuth>
                            }/>
                            <Route path={ROUTES.TEAM_FORM} element={
                                <RequireAuth>
                                    <RequireRole leastAllowedRole={ROLES.ORGANIZATION_ADMIN}>
                                        <TeamForm/>
                                    </RequireRole>
                                </RequireAuth>
                            }/>
                            <Route path={ROUTES.USER_FORM} element={
                                <RequireAuth>
                                    <RequireRole leastAllowedRole={ROLES.ORGANIZATION_ADMIN}>
                                        <UserForm/>
                                    </RequireRole>
                                </RequireAuth>
                            }/>
                            <Route path={ROUTES.USER_TABLE} element={
                                <RequireAuth>
                                    <RequireRole leastAllowedRole={ROLES.ORGANIZATION_ADMIN}>
                                        <UserTable/>
                                    </RequireRole>
                                </RequireAuth>
                            }/>
                            <Route path={ROUTES.DB_CONNECTION_TABLE} element={
                                <RequireAuth>
                                    <RequireRole leastAllowedRole={ROLES.DATABASE_ADMIN}>
                                        <DbConnectionTable/>
                                    </RequireRole>
                                </RequireAuth>
                            }/>
                            <Route path={ROUTES.DB_CONNECTION_FORM} element={
                                <RequireAuth>
                                    <RequireRole leastAllowedRole={ROLES.DATABASE_ADMIN}>
                                        <DbConnectionForm/>
                                    </RequireRole>
                                </RequireAuth>
                            }/>
                            <Route path={ROUTES.LOGIN} element={<Login/>}/>
                            <Route path={ROUTES.NOT_FOUND} element={<Page404/>}/>
                        </Route>
                    </Routes>
                </div>
            </ThemeProvider>
        </ColorModeContext.Provider>
    );
}

export default App;
