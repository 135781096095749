import React, {useState} from 'react';
import ReactDOM from 'react-dom';
import {Alert, Box, Button, List, Snackbar, Typography, useTheme} from '@mui/material';
import './AnalystRequestsList.css';
import {Reply} from "@mui/icons-material";
import {tokens} from "../../../theme";
import RequestItem from "./RequestItem";
import {useCancelQuestionToAnalystMutation, useGetMessagesSentToAnalystQuery} from "../../../store/emptySplitApi";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux"; // Import the CSS

const AnalystRequestsList = ({
                                 conversationId,
                                 isAnalystRequestsListWindowOpen,
                                 setIsAnalystRequestsListWindowOpen
                             }) => {
        const theme = useTheme();
        const colors = tokens(theme.palette.mode);
        const {t, i18n} = useTranslation();

        const {data: messagesSentToAnalyst = [], error} = useGetMessagesSentToAnalystQuery(conversationId, {
            polling: 5000,
        });
        const [cancelQuestionToAnalyst, {error: mutationError}] = useCancelQuestionToAnalystMutation();

        const [snackbarState, setSnackbarState] = useState({
            open: false,
            success: false,
            message: '',
        });

        const handleCloseSnackbar = (event, reason) => {
            if (reason === 'clickaway') {
                return;
            }

            setSnackbarState({
                ...snackbarState,
                open: false,
            });
        };

        const cancelQuestionToAnalystHandler = (messageId) => {
            return async () => {
                try {
                    let body = {
                        conversationId: conversationId,
                        messageId: messageId,
                    };
                    const result = await cancelQuestionToAnalyst(body);

                    if (result.data.error) {
                        setSnackbarState({
                            open: true,
                            success: false,
                            message: t('confidence_toaster_task_sent_failure')
                        });
                    } else {
                        setSnackbarState({open: true, success: true, message: t('confidence_toaster_task_sent_success')});
                    }

                } catch (error) {
                    console.log(`error: ${JSON.stringify(error)}`)
                }
            }
        }

        if (!isAnalystRequestsListWindowOpen) {
            return null;
        }

        const modalContent = (
            <div className="modal-overlay">
                <Box
                    className="modal-content"
                >
                    <Box
                        className="modal-header" // Apply the sticky header class here
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            borderBottom: `0.1px solid ${colors.grey[100]}`,
                            padding: "1rem 1.5rem",
                            "& .MuiButton-root": {
                                color: "#FAFAFA",
                                backgroundColor: colors.aliceBlue[600],
                                fontWeight: "600",
                            },
                            "& .MuiButton-root:hover": {
                                backgroundColor: '#56a9da',
                            }
                        }}
                    >
                        <Typography variant="h4">{t('my_chats_analytics_window_title')}</Typography>
                        <Button
                            variant="outlined"
                            endIcon={<Reply sx={{color: "#FAFAFA"}}/>}
                            onClick={() => setIsAnalystRequestsListWindowOpen(!isAnalystRequestsListWindowOpen)}
                            sx={{
                                "& .MuiButton-root": {
                                    color: "#FAFAFA",
                                    backgroundColor: colors.aliceBlue[600],
                                    fontWeight: "600",
                                },
                                "& .MuiButton-root:hover": {
                                    backgroundColor: '#56a9da',
                                }
                            }}
                        >
                            {t('my_chats_analytics_window_close_button')}
                        </Button>
                    </Box>
                    <List>
                        {messagesSentToAnalyst.map(m => (
                            <RequestItem
                                key={m._id}
                                userConnectedMessage={m.userConnectedMessage.text}
                                cancelQuestionToAnalystHandler={cancelQuestionToAnalystHandler(m._id)}
                            />
                        ))}
                    </List>
                </Box>

                <Snackbar
                    open={snackbarState.open}
                    autoHideDuration={1000}
                    onClose={handleCloseSnackbar}
                >
                    <Alert
                        severity={snackbarState.success ? "success" : "error"}
                        variant="filled"
                        sx={{width: '100%'}}
                        onClose={handleCloseSnackbar}
                    >
                        {snackbarState.message}
                    </Alert>
                </Snackbar>
            </div>
        );

        return ReactDOM.createPortal(modalContent, document.body);
    }
;

export default AnalystRequestsList;