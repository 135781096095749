import {Alert, Box, Button, Snackbar, Typography, useMediaQuery, useTheme} from "@mui/material";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup"
import Header from "../../components/Header";
import {tokens} from "../../theme";
import {
    useCreateDbConnectionMutation,
    useGetTeamLabelsQuery,
    useTestDbConnectionMutation
} from "../../store/emptySplitApi";
import React, {useState} from "react";
import {useForm} from "react-hook-form";
import StyledFormInput from "../../components/StyledFormInput";
import StyledFormBox from "../../components/StyledFormBox";
import StyledMultiSelectInput from "../../components/StyledMultiSelectInput";
import {useSelector} from "react-redux";
import {ROLES} from "../../util/constants";
import {useTranslation} from "react-i18next";

const dbTypes = [
    {dbType: 'postgresql', label: 'PostgreSQL'},
    {dbType: 'mysql', label: 'MySQL'},
    {dbType: 'vertica', label: 'Vertica'},
    {dbType: 'mongodb', label: 'MongoDB'},
    {dbType: 'oracle', label: 'Oracle'},
    {dbType: 'sqlite', label: 'SQLite'},
];

const DbConnectionForm = () => {
    const {t, i18n} = useTranslation();

    const dbConnectionSchema = yup.object().shape({
        name: yup.string().required(t('database_connection_creation_field1_required_message')),
        team: yup.string().required(t('database_connection_creation_field2_required_message')),
        dbType: yup.string().required(t('database_connection_creation_field3_required_message')),
        host: yup.string().required(t('database_connection_creation_field4_required_message')),
        port: yup.number().required(t('database_connection_creation_field5_required_message')),
        username: yup.string().required(t('database_connection_creation_field6_required_message')),
        password: yup.string().required(t('database_connection_creation_field7_required_message')),
        database: yup.string().required(t('database_connection_creation_field8_required_message')),
    });


    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const userTeam = useSelector(state => state.auth?.user?.user?.team);
    const role = useSelector(state => state.auth?.user?.user?.role);

    const {data: teamLabelsData = [], error: teamLabelsError} = useGetTeamLabelsQuery();

    const [createDbConnection, {error: mutationError}] = useCreateDbConnectionMutation();
    const [testDbConnection] = useTestDbConnectionMutation();
    const [generalError, setGeneralError] = React.useState(null);

    const isNoneMobile = useMediaQuery("(min-width: 600px)");

    const {
        formState: {errors},
        handleSubmit,
        control,
        reset,
        getValues,
    } = useForm({
        resolver: yupResolver(dbConnectionSchema),
        defaultValues: {
            name: "",
            team: "",
            dbType: "",
            host: "",
            port: null,
            username: "",
            password: "",
            database: "",
        },
        mode: 'onChange',
    });

    const handleTestDbConnectionButton = async () => {
        try {
            let currentFormValues = getValues();
            if (
                !currentFormValues.database ||
                !currentFormValues.dbType ||
                !currentFormValues.host ||
                !currentFormValues.name ||
                !currentFormValues.password ||
                !currentFormValues.port ||
                !currentFormValues.team ||
                !currentFormValues.username
            ) {
                setGeneralError(t('database_connection_test_connection_failure'));
                return;
            }

            const testDbConnectionData = await testDbConnection(currentFormValues);
            if (testDbConnectionData.data.result) {
                setGeneralError(null);
                setSnackbarState({open: true, success: true, message: t('database_connection_test_connection_success')});
            } else {
                setGeneralError(testDbConnectionData.data.message);
                setSnackbarState({open: true, success: false, message: t('database_connection_creation_failure_toaster')});
            }
        } catch (error) {
            console.log(`error: ${JSON.stringify(error)}`)
        }
    }

    const onSubmit = async (data) => {
        try {
            const createDbConnectionData = await createDbConnection(data);
            if (createDbConnectionData.error) {
                setGeneralError(createDbConnectionData.error.data.message);
            } else {
                setGeneralError(null);
                setSnackbarState({
                    open: true,
                    success: true,
                    message: t('database_connection_creation_success_toaster')
                });
                reset();
            }
        } catch (error) {
            console.log(`error: ${JSON.stringify(error)}`)
        }
    }

    const [snackbarState, setSnackbarState] = useState({
        open: false,
        success: false,
        message: '',
    });

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarState({
            ...snackbarState,
            open: false,
            message: '',
        });
    };

    return (
        <Box m="20px">
            <Header title={t('database_connection_creation_title')}
                    subtitle={t('database_connection_creation_subtitle')}/>
            <StyledFormBox>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Box
                        display="grid"
                        gap="30px"
                        gridTemplateColumns="repeat(8, minmax(0, 1fr))"
                        sx={{
                            "& > div": {gridColumn: isNoneMobile ? undefined : "span 8"}
                        }}
                    >
                        <StyledFormInput
                            label={t('database_connection_creation_input_field1_title')}
                            name="name"
                            control={control}
                            errors={errors.name}
                        />

                        <StyledMultiSelectInput
                            label={t('database_connection_creation_input_field2_title')}
                            name='team'
                            control={control}
                            values={
                                [ROLES.ADMIN, ROLES.ORGANIZATION_ADMIN].includes(role) ?
                                    teamLabelsData :
                                    teamLabelsData.filter((teamLabel) => teamLabel.team === userTeam)
                            }
                            errors={errors.team}
                        />
                        <StyledMultiSelectInput
                            label={t('database_connection_creation_input_field3_title')}
                            name='dbType'
                            control={control}
                            values={dbTypes}
                            errors={errors.dbType}
                        />

                        <StyledFormInput
                            label={t('database_connection_creation_input_field4_title')}
                            name="host"
                            control={control}
                            errors={errors.host}
                        />
                        <StyledFormInput
                            label={t('database_connection_creation_input_field5_title')}
                            name="port"
                            control={control}
                            errors={errors.port}
                        />

                        <StyledFormInput
                            label={t('database_connection_creation_input_field6_title')}
                            name="username"
                            control={control}
                            errors={errors.username}
                        />

                        <StyledFormInput
                            label={t('database_connection_creation_input_field7_title')}
                            name="password"
                            control={control}
                            type="password"
                            errors={errors.password}
                        />
                        <StyledFormInput
                            label={t('database_connection_creation_input_field8_title')}
                            name="database"
                            control={control}
                            errors={errors.database}
                        />

                    </Box>
                    <Box display="flex" justifyContent="end" mt="20px" gap="2rem">
                        <Button
                            type="submit"
                            color="secondary"
                            variant="contained"
                            sx={{fontWeight: "600"}}
                        >
                            {t('database_connection_creation_submit_button')}
                        </Button>
                    </Box>
                </form>
                <Button
                    onClick={handleTestDbConnectionButton}
                    color="secondary"
                    variant="contained"
                    sx={{fontWeight: "600"}}
                >
                    {t('database_connection_creation_test_connection_button')}
                </Button>
                <Snackbar
                    open={snackbarState.open}
                    autoHideDuration={1500}
                    onClose={handleCloseSnackbar}
                >
                    <Alert
                        severity={snackbarState.success ? "success" : "error"}
                        variant="filled"
                        sx={{width: '100%'}}
                        onClose={handleCloseSnackbar}
                    >
                        {snackbarState.message}
                    </Alert>
                </Snackbar>
                {generalError && (
                    <Typography
                        variant="h4"
                        color={colors.redAccent[500]}
                        mt="15px"
                    >
                        {generalError}
                    </Typography>
                )}
            </StyledFormBox>
        </Box>
    )
}

export default DbConnectionForm;