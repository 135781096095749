const conversationEndpointsBuilder = (builder) => {
    return {
        getConversation: builder.query({
            query: (id) => (
                {
                    url: `conversation/${id}`,
                    method: 'GET',
                }
            ),
            transformResponse(baseQueryReturnValue, meta, arg) {
                return baseQueryReturnValue;
            },
            transformErrorResponse(baseQueryError, meta, arg) {
                return baseQueryError;
            }
        }),
        getConversations: builder.query({
            query: () => ({
                url: 'conversation',
                method: 'GET',
            }),
            providesTags: (result) =>
                result
                    ? [...result.map(({id}) => ({type: 'Conversations', id})), 'Conversations']
                    : ['Conversations'],
            transformResponse(baseQueryReturnValue, meta, arg) {
                return baseQueryReturnValue;
            },
        }),
        startConversation: builder.mutation({
            query: (body) => ({
                url: 'conversation/start',
                method: 'POST',
                body
            }),
            invalidatesTags: ['Conversations'],
            transformResponse(baseQueryReturnValue, meta, arg) {
                return baseQueryReturnValue;
            },
            transformErrorResponse(baseQueryError, meta, arg) {
                return baseQueryError;
            }
        }),
        hideConversation: builder.mutation({
            query: (body) => ({
                url: 'conversation/hideConversation',
                method: 'POST',
                body,
            }),
            invalidatesTags: ['Conversations'],
        }),
        getConversationMessages: builder.query({
            query: (id) => (
                {
                    url: `conversation/${id}/messages`,
                    method: 'GET',
                }
            ),
            providesTags: (result) =>
                result
                    ? [...result.map(({id}) => ({type: 'Messages', id})), 'Messages']
                    : ['Messages'],
            transformResponse(baseQueryReturnValue, meta, arg) {
                return baseQueryReturnValue;
            },
        }),
        sendMessage: builder.mutation({
            query: (body) => ({
                url: `conversation/${body.conversationId}/message`,
                method: 'POST',
                body
            }),
            invalidatesTags: ['Messages'],
            transformResponse(baseQueryReturnValue, meta, arg) {
                return baseQueryReturnValue;
            },
            transformErrorResponse(baseQueryError, meta, arg) {
                return baseQueryError;
            }
        }),
        cancelQuestionToAnalyst: builder.mutation({
            query: (body) => ({
                url: `conversation/${body.conversationId}/cancelQuestionToAnalyst`,
                method: 'POST',
                body
            }),
            invalidatesTags: ['MessagesSentToAnalyst'],
            transformResponse(baseQueryReturnValue, meta, arg) {
                return baseQueryReturnValue;
            },
            transformErrorResponse(baseQueryError, meta, arg) {
                return baseQueryError;
            }
        }),
        sendQuestionToAnalyst: builder.mutation({
            query: (body) => ({
                url: `conversation/${body.conversationId}/sendQuestionToAnalyst`,
                method: 'POST',
                body
            }),
            invalidatesTags: ['MessagesSentToAnalyst'],
            transformResponse(baseQueryReturnValue, meta, arg) {
                return baseQueryReturnValue;
            },
            transformErrorResponse(baseQueryError, meta, arg) {
                return baseQueryError;
            }
        }),
        getMessagesSentToAnalyst: builder.query({
            query: (id) => (
                {
                    url: `conversation/${id}/getMessagesSentToAnalyst`,
                    method: 'GET',
                }
            ),
            providesTags: (result) =>
                result
                    ? [...result.map(({id}) => ({type: 'MessagesSentToAnalyst', id})), 'MessagesSentToAnalyst']
                    : ['MessagesSentToAnalyst'],
            transformResponse(baseQueryReturnValue, meta, arg) {
                return baseQueryReturnValue;
            },
        }),
    }
};

export default conversationEndpointsBuilder;