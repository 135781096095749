import LoginForm from "./LoginForm";
import {StyledLoginH3, StyledLoginH4} from "./styled";
import "./Login.css";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

const LoginFormDesktop = ({fromPage}) => {
    const { t, i18n } = useTranslation();

    return (
        <>
            <div className="login__content-wrapper">
                <div className="login__content">
                    <StyledLoginH3>
                        {t('welcome_title')}
                    </StyledLoginH3>
                    <StyledLoginH4>
                        {t('welcome_subtitle')}
                    </StyledLoginH4>

                    <LoginForm fromPage={fromPage}/>
                </div>
            </div>
            <div className="login__image"></div>
        </>
    );
}

export default LoginFormDesktop;