import React from 'react';
import Box from '@mui/material/Box';
import {useTheme} from "@mui/material";
import {tokens} from "../theme";

const StyledFormBox = ({children, ...props}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
        <Box
            p={5}
            backgroundColor={colors.utility.form}
            borderRadius={5}
            sx={{
                "& .MuiInputLabel-root": {},
                "& .MuiFormLabel-filled": {
                    fontWeight: "500",
                },
                "& .MuiInputLabel-shrink": {
                    fontWeight: "500",
                },
                "& .MuiInputLabel-formControl": {
                    color: `${colors.primary[100]}`,
                },
                "& .MuiOutlinedInput-root": {
                    borderRadius: "10px !important",
                    backgroundColor: colors.utility.input,
                },
                "& .MuiOutlinedInput-input:-webkit-autofill": {
                    WebkitBoxShadow: `0 0 0 100px ${colors.utility.autoComplete} inset`,
                    WebkitTextFillColor: "#FAFAFA !important",
                },
                padding: "30px 40px"
            }}
            {...props} // Spread additional props to allow custom overrides
        >
            {children}
        </Box>
    );
};

export default StyledFormBox;
