import React from 'react';
import {Box, Button, OutlinedInput, Typography} from "@mui/material";
import './Login.css';
import * as yup from "yup";
import {Controller, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {setUser} from "../../store/authSlice/authSlice";
import {useNavigate} from "react-router-dom";
import {api, useLoginMutation} from "../../store/emptySplitApi";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

const loginSchema = yup.object().shape({
    email: yup.string().email("Invalid email").required("Email is required"),
    password: yup.string().required("Password is required"),
});

const LoginForm = ({fromPage}) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [login, {isLoading}] = useLoginMutation();
    const dispatch = useDispatch();

    const [error, setError] = React.useState(null);

    const onSubmit = async (loginFormData) => {
        console.log(`data: ${JSON.stringify(loginFormData)}`)

        try {
            const loginData = await login(loginFormData).unwrap();

            console.log(loginData)

            if (loginData.error) {
                setError(loginData.error.data.message);
            } else {
                dispatch(api.util.resetApiState());
                dispatch(setUser(loginData));
                console.log('fromPage:')
                console.log(fromPage)
                navigate(fromPage, {replace: true});
            }

        } catch (error) {
            console.log(`error: ${JSON.stringify(error)}`)
            setError(error.data.message);
        }
    }

    const {
        formState: {errors},
        handleSubmit,
        control,
        reset,
    } = useForm({
        resolver: yupResolver(loginSchema),
        defaultValues: {
            email: "",
            password: "",
        },
        mode: 'onSubmit',
    });

    return (
        <Box
            border="2px solid rgba(255, 255, 255, .2)"
            sx={{
                marginTop: "60px",
                "& .MuiOutlinedInput-input": {
                    fontSize: "1rem !important",
                    color: "#040404 !important",
                    border: "1px #D9D9D9 solid !important",
                    borderRadius: "10px !important",
                },
                "& .MuiOutlinedInput-input:-webkit-autofill": {
                    WebkitBoxShadow: "none !important",
                    WebkitTextFillColor: "#040404 !important",
                },
                "& .MuiFormHelperText-root": {
                    fontWeight: "500",
                    fontSize: "1rem"
                },
            }}
            color="#040404"
        >
            <form
                onSubmit={handleSubmit(onSubmit)}
                style={{
                }}
            >

                <Typography
                    variant="h4"
                    sx={{
                        fontWeight: "600",
                        fontSize: "14px",
                        marginBottom: '5px',
                        marginLeft: '5px',
                    }}
                >
                    Email
                </Typography>
                <Controller
                    render={({field}) => (
                        <OutlinedInput
                            placeholder="Email"
                            fullWidth
                            sx={{
                                fontSize: "1rem"
                            }}
                            {...field}
                        />
                    )
                    }
                    name="email"
                    control={control}
                />
                {errors.email && (
                    <Box
                        color="#ED0131"
                        fontSize="16px"
                    >
                        {errors.email.message}
                    </Box>
                )}

                <Typography
                    variant="h4"
                    sx={{
                        fontWeight: "600",
                        fontSize: "14px",
                        marginTop: "20px",
                        marginBottom: '5px',
                        marginLeft: '5px',
                    }}
                >
                    Password
                </Typography>
                <Controller
                    render={({field}) => (
                        <OutlinedInput
                            placeholder="Password"
                            type="password"
                            fullWidth
                            sx={{
                                fontSize: "1rem"
                            }}
                            {...field}
                        />
                    )
                    }
                    name="password"
                    control={control}
                />
                {errors.password && (
                    <Box
                        color="#ED0131"
                        fontSize="16px"
                    >
                        {errors.password.message}
                    </Box>
                )}

                <Button
                    type="submit"
                    color="secondary"
                    variant="contained"
                    sx={{
                        marginTop: "35px",
                        fontSize: "14px",
                        fontWeight: "600",
                        backgroundColor: "#3A5B22",
                        color: "#FFFFFF",
                        padding: "5px 10px",
                        width: "100%"
                    }}
                >
                    {t('login_button')}
                </Button>
                {error && (
                    <Typography
                        variant="h4"
                        color="#db4f4a"
                        mt="15px"
                    >
                        {error}
                    </Typography>
                )}
            </form>
        </Box>
    );
}

export default LoginForm;