const userEndpointsBuilder = (builder) => {
    return {
        getUser: builder.query({
            query: (id) => `user/${id}`
        }),
        getUsers: builder.query({
            query: () => 'user',
            providesTags: (result) =>
                result
                    ? [...result.map(({ id }) => ({ type: 'Users', id })), 'Users']
                    : ['Users'],
        }),
        createUser: builder.mutation({
            query: (body) => ({
                url: 'user',
                method: 'POST',
                body
            }),
            invalidatesTags: ['Users']
        }),
        updateUser: builder.mutation({
            query: ({id, body}) => ({
                url: `user/${id}`,
                method: 'PUT',
                body
            }),
            invalidatesTags: ['Users']
        }),
        deleteUser: builder.mutation({
            query: (id) => ({
                url: `user/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Users']
        }),
    }
};

export default userEndpointsBuilder;