import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
} from "@mui/material";
import {DataGrid} from "@mui/x-data-grid";
import Header from "../../components/Header";
import {DeleteOutlineOutlined, EditOutlined} from "@mui/icons-material";
import React, {useEffect} from "react";
import {useDeleteUserMutation, useGetUsersQuery} from "../../store/emptySplitApi";
import StyledDataGridBox from "../../components/StyledDataGridBox";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

const UserTable = () => {
    const {t, i18n} = useTranslation();
    const [deletionUser, setDeletionUser] = React.useState(null);
    const {data = [], refetch} = useGetUsersQuery();

    const [deleteUser, {isLoading}] = useDeleteUserMutation();

    useEffect(() => {
        refetch();
    }, []);

    const handleDeleteUser = async () => {
        try {
            const deleteUserData = await deleteUser(deletionUser);

            if (deleteUserData.error) {
                console.log(`error: ${JSON.stringify(deleteUserData.error)}`)
            } else {
            }

            setDeletionUser(null);
            refetch();
        } catch (error) {
            console.log(`error: ${JSON.stringify(error)}`)
        }
    };


    const handleDeleteUserPopupClose = () => {
        setDeletionUser(null);
    };

    const columns = [
        {
            field: "fullname",
            headerName: t('user_management_column1_title'),
            flex: 1,
            cellClassName: "name-column--cell"
        },
        {field: "email", headerName: t('user_management_column2_title'), flex: 1},
        {field: "role", headerName: t('user_management_column3_title'), flex: 1},
        {
            field: "team",
            headerName: t('user_management_column4_title'),
            flex: 1,
            valueGetter: ({row: {team}}) => team?.name ? team.name : 'Команда не назначена'
        },
        {
            field: "actions",
            headerName: t('user_management_column5_title'),
            flex: 0.4,
            renderCell: ({row: {_id}}) => (
                <Box
                    width="60%"
                    m="0 auto"
                    p="5px"
                    display="flex"
                    justifyContent="center"
                    gap="20px"
                >
                    <IconButton onClick={() => console.log(`edit: ${_id} user`)}>
                        <EditOutlined/>
                    </IconButton>
                    <IconButton onClick={() => setDeletionUser(_id)}>
                        <DeleteOutlineOutlined/>
                    </IconButton>
                </Box>
            )
        },
    ];

    return (
        <Box m="20px">
            <Header title={t('user_management_title')} subtitle={t('user_management_subtitle')}/>
            <StyledDataGridBox>
                <DataGrid
                    rows={data}
                    columns={columns}
                    getRowId={(row) => row._id}
                />
            </StyledDataGridBox>
            <Dialog
                open={!!deletionUser}
                onClose={handleDeleteUserPopupClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {t('user_management_action_delete_title')}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {t('user_management_action_delete_text')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteUserPopupClose}>Отмена</Button>
                    <Button onClick={handleDeleteUser} autoFocus>
                        {t('user_management_action_delete_submit_button')}
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
}

export default UserTable;

