import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
} from "@mui/material";
import {DataGrid} from "@mui/x-data-grid";
import Header from "../../components/Header";
import {DeleteOutlineOutlined, EditOutlined} from '@mui/icons-material';
import React, {useEffect} from "react";
import {useDeleteOrganizationMutation, useGetOrganizationsQuery} from "../../store/emptySplitApi";
import StyledDataGridBox from "../../components/StyledDataGridBox";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

const OrganizationTable = () => {
    const { t, i18n } = useTranslation();
    const [deletionOrganization, setDeletionOrganization] = React.useState(null);
    const {data = [], refetch} = useGetOrganizationsQuery();

    const [deleteOrganization, {isLoading}] = useDeleteOrganizationMutation();

    useEffect(() => {
        refetch();
    }, []);

    const handleDeleteOrganization = async () => {
        try {
            const deleteOrganizationData = await deleteOrganization(deletionOrganization);

            if (deleteOrganizationData.error) {
                console.log(`error: ${JSON.stringify(deleteOrganizationData.error)}`)
            } else {
            }

            setDeletionOrganization(null);
            refetch();
        } catch (error) {
            console.log(`error: ${JSON.stringify(error)}`)
        }
    };


    const handleDeleteOrganizationPopupClose = () => {
        setDeletionOrganization(null);
    };

    const columns = [
        {field: "name", headerName: t('organization_management_column1_title'), flex: 1, cellClassName: "name-column--cell"},
        {
            field: "admins",
            headerName: t('organization_management_column2_title'),
            flex: 1,
            valueGetter: ({row: {admins}}) => admins.map(a => a.fullname).join(', \n')
        },
        {
            field: "actions",
            headerName: t('organization_management_column3_title'),
            flex: 0.2,
            renderCell: ({row: {_id}}) => (
                <Box
                    width="60%"
                    m="0 auto"
                    p="5px"
                    display="flex"
                    justifyContent="center"
                    gap="20px"
                >
                    <IconButton onClick={() => console.log(`edit: ${_id} organization`)}>
                        <EditOutlined/>
                    </IconButton>
                    <IconButton onClick={() => setDeletionOrganization(_id)}>
                        <DeleteOutlineOutlined/>
                    </IconButton>
                </Box>
            )
        },
    ];

    return (
        <Box m="20px">
            <Header title={t('organization_management_title')} subtitle={t('organization_management_subtitle')}/>

            <StyledDataGridBox>
                <DataGrid
                    rows={data}
                    columns={columns}
                    getRowId={(row) => row._id}
                />
            </StyledDataGridBox>
            <Dialog
                open={!!deletionOrganization}
                onClose={handleDeleteOrganizationPopupClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {t('organization_management_action_delete_title')}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {t('organization_management_action_delete_text')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteOrganizationPopupClose}>Отмена</Button>
                    <Button onClick={handleDeleteOrganization} autoFocus>
                        {t('organization_management_action_delete_submit_button')}
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
}

export default OrganizationTable;