import React from 'react';
import Box from '@mui/material/Box';
import {useTheme} from "@mui/material";
import {tokens} from "../theme";

const StyledDataGridBox = ({children, height, ...props}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
        <Box
            m="40px 0 0 0"
            // height="75vh"
            height={height ? height : "75vh"}
            sx={{
                "& .MuiDataGrid-root": {
                    border: "none",
                },
                "& .MuiDataGrid-cell": {
                    border: "none",
                    fontSize: "0.9rem",
                    fontWeight: "450",
                    borderBottom: "0.3mm solid black",
                    borderRight: "0.3mm solid black",
                },
                "& .MuiDataGrid-virtualScrollerContent": {
                    backgroundColor: colors.utility.dataGridBackground,
                },
                "& .MuiDataGrid-columnHeaders": {
                    backgroundColor: colors.utility.form,
                    borderBottom: "none",
                    fontSize: "1rem",
                },
                "& .MuiDataGrid-virtualScroller": {
                    backgroundColor: colors.primary[400],
                },
                "& .MuiDataGrid-footerContainer": {
                    borderTop: "none",
                    backgroundColor: colors.utility.form,
                },
            }}
            {...props} // Spread additional props to allow custom overrides
        >
            {children}
        </Box>
    );
};

export default StyledDataGridBox;
