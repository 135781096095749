import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

const authSlice = createSlice({
    name: 'auth',
    initialState: {
        loading: false,
        isAuthenticated: false,
        user: null,
        error: null
    },
    reducers: {
        setUser: (state, action) => {
            state.user = {
                ...action.payload
            };
            state.isAuthenticated = true;
        },
        logout: (state) => {
            state.user = null;
            state.isAuthenticated = false;
        },
        updateAccessToken: (state, action) => {
            state.user.accessToken = action.payload;
        },
    },
});


export default authSlice.reducer;
export const {setUser, updateAccessToken, logout} = authSlice.actions;