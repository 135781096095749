import React, {useState} from 'react';
import {Alert, Box, Button, IconButton, Snackbar, Tooltip, Typography, useMediaQuery, useTheme} from "@mui/material";
import {Delete, EmailOutlined, ListOutlined} from "@mui/icons-material";
import {tokens} from "../../theme";
import AnalystRequestsList from "./AnalystRequestsList/AnalystRequestsList";
import {useTranslation} from "react-i18next";
import {useHideConversationMutation} from "../../store/emptySplitApi";
import {useNavigate} from "react-router-dom";

const ConversationHeader = ({conversationId, conversationTitle}) => {
    const {t, i18n} = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const navigate = useNavigate();

    const [isAnalystRequestsListWindowOpen, setIsAnalystRequestsListWindowOpen] = React.useState(false);

    const [hideConversation, {error: mutationError}] = useHideConversationMutation();

    const openAnalystsRequests = (e) => {
        e.preventDefault();
        setIsAnalystRequestsListWindowOpen(!isAnalystRequestsListWindowOpen);
    }

    const matches = useMediaQuery(theme.breakpoints.down('md'));

    const [snackbarState, setSnackbarState] = useState({
        open: false,
        success: false,
        message: '',
    });

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarState({
            ...snackbarState,
            open: false,
            message: '',
        });
    };

    const deleteConversation = async () => {
        try {
            const hideConversationData = await hideConversation({conversationId});
            if (hideConversationData.error) {
                setSnackbarState({
                    open: true,
                    success: false,
                    message: t('my_chats_analytics_window_delete_button_snackbar_failure')
                });
            } else {
                setSnackbarState({
                    open: true,
                    success: true,
                    message: t('my_chats_analytics_window_delete_button_snackbar_success')
                });
                setTimeout(() => {
                    navigate('/conversations');
                }, 1000);
            }

        } catch (error) {
            console.log(`error: ${JSON.stringify(error)}`)
        }
    };

    return (
        <>
            <Box
                sx={{
                    backgroundColor: colors.aliceBlue[700],
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "1rem 2rem",
                    maxWidth: matches ? undefined : "100%",
                    width: matches ? '100vw' : undefined,
                }}
            >
                <Box
                    display="flex"
                    alignItems="center"
                    gap="10px"
                >
                    <EmailOutlined/>
                    <Typography variant="h3">
                        {conversationTitle}
                    </Typography>
                </Box>
                <Box
                    display="flex"
                    gap="1rem"
                    alignItems="center"
                    fontSize="medium"
                    sx={{
                        margin: "0.3rem 0 0.3rem 1.2rem",
                        p: "0.1rem",
                        color: colors.greenAccent[700],
                        fontWeight: "600",
                        fontSize: "0.9rem",
                        "& .MuiButton-root": {
                            color: "#FAFAFA",
                            backgroundColor: colors.aliceBlue[600],
                            fontWeight: "600",
                        },
                        "& .MuiButton-root:hover": {
                            backgroundColor: '#56a9da',
                        }
                    }}
                >

                    <Button
                        variant="outlined"
                        startIcon={<ListOutlined/>}
                        onClick={openAnalystsRequests}
                    >
                        {t('my_chats_analytics_window_show_button')}
                    </Button>
                    <Tooltip
                        title={
                            <Typography sx={{fontSize: "0.8rem"}}>
                                {t('my_chats_analytics_window_delete_button_tooltip')}
                            </Typography>
                        }
                        leaveDelay={200}
                        arrow={true}
                        PopperProps={{style: {zIndex: 2010}}}
                    >
                        <IconButton
                            onClick={deleteConversation}
                        >
                            <Delete sx={{
                                fontSize: '2.5rem',
                                color: '#db4f4a'
                            }}/>
                        </IconButton>
                    </Tooltip>
                </Box>
            </Box>
            <AnalystRequestsList
                conversationId={conversationId}
                isAnalystRequestsListWindowOpen={isAnalystRequestsListWindowOpen}
                setIsAnalystRequestsListWindowOpen={setIsAnalystRequestsListWindowOpen}
            />
            <Snackbar
                open={snackbarState.open}
                autoHideDuration={1500}
                onClose={handleCloseSnackbar}
            >
                <Alert
                    severity={snackbarState.success ? "success" : "error"}
                    variant="filled"
                    sx={{width: '100%'}}
                    onClose={handleCloseSnackbar}
                >
                    {snackbarState.message}
                </Alert>
            </Snackbar>
        </>
    );
};

export default ConversationHeader;