import React from 'react';
import {Dialog, DialogContent, DialogTitle, useTheme,} from "@mui/material";
import ConversationForm from "../ConversationForm";
import {tokens} from "../../../theme";

const ConversationCreationDialog = ({isOpen, onClose}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"

            sx={{
                "& .MuiDialog-paper": {
                    background: `${colors.primary[400]} !important`,
                    color: `${colors.grey[100]} !important`,
                    border: `1px solid ${colors.greenAccent[500]} !important`,
                    minWidth: "60%",
                    [theme.breakpoints.down('md')]: {
                        minWidth: "80%",
                    },
                    [theme.breakpoints.down('sm')]: {
                        minWidth: "98%",
                    }
                },
                "& .MuiDialogTitle-root": {
                    borderBottom: `1px solid ${colors.greenAccent[500]} !important`
                },
                "& .MuiDialogContent-root": {
                    borderBottom: `1px solid ${colors.greenAccent[500]} !important`,
                    minWidth: "40%",
                    minHeight: "30%",
                },
                "& .MuiDialogActions-root": {
                    borderTop: `1px solid ${colors.greenAccent[500]} !important`
                },
            }}
        >
            <DialogTitle sx={{fontSize: "1.25rem"}}>
                Создать новый чат
            </DialogTitle>
            <DialogContent>
                <ConversationForm handleCreationDialogClose={onClose}/>
            </DialogContent>
        </Dialog>
    );
};


export default ConversationCreationDialog;
