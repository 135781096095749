import React, {useEffect, useState} from 'react';
import {DataGrid} from "@mui/x-data-grid";
import {v4 as uuidv4} from 'uuid';
import StyledDataGridBox from "../../components/StyledDataGridBox";

const QueryResult = ({data}) => {

    const [initialValues, setInitialValues] = useState({});
    const [columns, setColumns] = useState([]);

    useEffect(() => {
        if (data?.length > 0) {
            const initialValuesTmp = {};
            const columnsTmp = [];

            Object.keys(data[0]).forEach(key => {
                initialValuesTmp[key] = '';

                columnsTmp.push({
                    field: key,
                    headerName: key,
                    flex: 1,
                })
            });

            setInitialValues((prevState) => initialValuesTmp);
            setColumns((prevState) => columnsTmp);
        }

    }, [data]);


    return (
        <StyledDataGridBox
            height="40vh"
        >
            <DataGrid
                rows={data}
                columns={columns}
                getRowId={(_) => uuidv4()}
                pagination
                autoPageSize
            />
        </StyledDataGridBox>
    );
};

export default QueryResult;