import React, { useState } from 'react';
import { MenuOutlined, AddToPhotosOutlined, QuestionAnswerOutlined } from "@mui/icons-material";
import SidebarItem from "../../global/SidebarItem";
import { useGetConversationsQuery } from "../../../store/emptySplitApi";
import ConversationCreationDialog from './ConversationCreationDialog';
import {
    Box,
    Divider,
    useTheme,
} from "@mui/material";
import {tokens} from "../../../theme";
import {Menu, ProSidebar} from "react-pro-sidebar";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

const ConversationSidebar = ({setIsCreationDialogOpen}) => {
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { data: conversationsData } = useGetConversationsQuery(undefined, { pollingInterval: 2000 });

    return (
        <Box
            sx={{
                "& .pro-sidebar-inner": {
                    background: `${colors.primary[400]} !important`,
                    borderLeft: `0.5mm solid ${colors.grey[100]}26`,
                    borderRight: `0.5mm solid ${colors.grey[100]}26`,
                    // borderColor: "#ffffff26",
                },
                "& .pro-icon-wrapper": {
                    backgroundColor: "transparent !important"
                },
                "& .pro-inner-item": {
                    color: colors.grey[100],
                    fontWeight: "500",
                },
                "& .pro-inner-item:hover": {
                    color: "#4cceac !important",
                },
                "& .pro-menu-item.active": {
                    color: "#4cceac !important",
                },
                "& .pro-menu-item": {
                    padding: "0.2rem 0 0 0"
                },
            }}
        >
            <ProSidebar>
                <Menu iconShape="square">
                    <SidebarItem
                        icon={<MenuOutlined />}
                        title={t('my_chats_sidebar_title')}
                        onClickFunction={() => console.log()}
                        isSidebarOpen={true}
                    />
                    <Divider />
                    <SidebarItem
                        onClickFunction={() => setIsCreationDialogOpen(true)}
                        icon={<AddToPhotosOutlined />}
                        title={t('my_chats_sidebar_create_chat')}
                        isSidebarOpen={true}
                    />
                    <Divider />
                    {conversationsData && conversationsData.map(conversation => (
                        <SidebarItem
                            key={conversation._id}
                            title={conversation.title}
                            to={`/conversations/${conversation._id}`}
                            icon={<QuestionAnswerOutlined />}
                            isSidebarOpen={true}
                        />
                    ))}
                </Menu>
            </ProSidebar>
        </Box>
    );
};

export default ConversationSidebar;