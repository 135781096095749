import {Box, Grid, Typography} from "@mui/material";

const DescriptionCard = ({title, description}) => {
    return (
        <Box sx={{
            border: "0.3mm solid",
            borderRadius: "0.5rem",
            minHeight: "10rem",
            padding: "1rem",
            margin: "1%",
            flex: '48%',
        }}>
            <Typography variant="h3" sx={{paddingBottom: "1rem"}}>{title}</Typography>
            <Typography>{description}</Typography>
        </Box>
    )
};

export default DescriptionCard