import {Divider, Drawer, IconButton, List, ListItemButton, ListItemText, useTheme} from "@mui/material";
import {tokens} from "../../../theme";
import {useGetConversationsQuery} from "../../../store/emptySplitApi";
import {setIsConversationsDrawerOpen, setIsDrawerOpen} from "../../../store/applicationSlice/applicationSlice";
import {AddToPhotosOutlined, Close, MenuOutlined, QuestionAnswerOutlined} from "@mui/icons-material";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {NavLink} from "react-router-dom";
import {useTranslation} from "react-i18next";

const ConversationsSidebarDrawer = ({setIsCreationDialogOpen}) => {
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const dispatch = useDispatch();

    const {data: conversationsData} = useGetConversationsQuery(undefined, {pollingInterval: 10000});
    const isConversationsDrawerOpen = useSelector(state => state.application.isConversationsDrawerOpen);

    return (
        <>
            {isConversationsDrawerOpen && (
                <IconButton
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: '70%',
                        zIndex: 1999
                    }}
                    onClick={() => dispatch(setIsConversationsDrawerOpen())}>
                    <Close sx={{fontSize: '2.5rem'}}/>
                </IconButton>
            )}
            <Drawer
                open={isConversationsDrawerOpen}
                sx={{
                    "& .MuiDrawer-paper": {
                        width: '70%',
                    },
                    "& .MuiListItemButton-root": {
                        width: '100%',
                    }
                }}
            >
                <List>
                    <ListItemButton
                        disableRipple
                        disableGutters
                        disableTouchRipple
                        sx={{
                            marginLeft: "1rem",
                            display: 'flex',
                            gap: "1rem",
                            alignItems: 'center',
                            "& .MuiTypography-root": {
                                fontSize: "1.5rem",
                            },
                            "& .MuiSvgIcon-root": {
                                fontSize: "1.5rem",
                            },
                        }}
                    >
                        <MenuOutlined/>
                        <ListItemText>{t('my_chats_sidebar_title')}</ListItemText>
                    </ListItemButton>
                    <Divider variant="middle"/>

                    <ListItemButton
                        onClick={() => setIsCreationDialogOpen(true)}
                        sx={{
                            display: 'flex',
                            gap: "1rem",
                            alignItems: 'center',
                        }}
                    >
                        <AddToPhotosOutlined/>
                        <ListItemText>{t('my_chats_sidebar_create_chat')}</ListItemText>
                    </ListItemButton>
                    <Divider variant="middle"/>


                    {/*<SidebarItem*/}
                    {/*    icon={<MenuOutlined />}*/}
                    {/*    title="Активные чаты"*/}
                    {/*    onClickFunction={() => console.log()}*/}
                    {/*    isSidebarOpen={true}*/}
                    {/*/>*/}
                    {/*<Divider />*/}
                    {/*<SidebarItem*/}
                    {/*    onClickFunction={() => setIsCreationDialogOpen(true)}*/}
                    {/*    icon={<AddToPhotosOutlined />}*/}
                    {/*    title="Создать новый чат"*/}
                    {/*    isSidebarOpen={true}*/}
                    {/*/>*/}
                    {/*<Divider />*/}

                    {/*{SIDEBAR_ITEMS.map(r => (*/}
                    {/*    <React.Fragment key={r.title}>*/}
                    {/*        <ListItemButton*/}
                    {/*            onClick={() => dispatch(setIsDrawerOpen())}*/}
                    {/*        >*/}
                    {/*            <ListItemIcon>*/}
                    {/*                {r.icon}*/}
                    {/*            </ListItemIcon>*/}
                    {/*            <NavLink to={r.route} style={{width: '100%'}}>*/}
                    {/*                <ListItemText>{r.title}</ListItemText>*/}
                    {/*            </NavLink>*/}
                    {/*        </ListItemButton>*/}
                    {/*        <Divider variant="middle"/>*/}
                    {/*    </React.Fragment>*/}
                    {/*))}*/}

                    {conversationsData && conversationsData.map(conversation => (
                        // <SidebarItem
                        //     key={conversation._id}
                        //     title={conversation.title}
                        //     to={`/conversations/${conversation._id}`}
                        //     icon={<QuestionAnswerOutlined />}
                        //     isSidebarOpen={true}
                        // />


                        <ListItemButton
                            key={conversation._id}
                            onClick={() => dispatch(setIsConversationsDrawerOpen())}
                            sx={{
                                display: 'flex',
                                gap: "1rem",
                                alignItems: 'center',
                            }}
                        >
                            <QuestionAnswerOutlined />
                            <NavLink to={`/conversations/${conversation._id}`} style={{width: '100%'}}>
                                <ListItemText>{conversation.title}</ListItemText>
                            </NavLink>
                        </ListItemButton>
                    ))}

                    {/*    <Typography*/}
                    {/*        noWrap={true}*/}
                    {/*        variaht="h6"*/}
                    {/*        color={colors.grey[300]}*/}
                    {/*        sx={{m: "2rem 0 0.3rem 1rem"}}*/}
                    {/*    >*/}
                    {/*        Общие*/}
                    {/*    </Typography>*/}
                    {/*    <Divider variant="middle"/>*/}

                    {/*    <ListItemButton*/}
                    {/*        onClick={onLogout}*/}
                    {/*    >*/}
                    {/*        <ListItemText>Выйти</ListItemText>*/}
                    {/*    </ListItemButton>*/}
                    {/*    <Divider variant="middle"/>*/}

                    {/*    {theme.palette.mode === 'dark' ? (*/}
                    {/*        <ListItemButton*/}
                    {/*            onClick={colorMode.toggleColorMode}*/}
                    {/*        >*/}
                    {/*            <ListItemText>Светлая тема</ListItemText>*/}
                    {/*        </ListItemButton>*/}
                    {/*    ) : (*/}
                    {/*        <ListItemButton*/}
                    {/*            onClick={colorMode.toggleColorMode}*/}
                    {/*        >*/}
                    {/*            <ListItemText>Тёмная тема</ListItemText>*/}
                    {/*        </ListItemButton>*/}
                    {/*    )}*/}
                </List>
            </Drawer>
        </>
    );
};

export default ConversationsSidebarDrawer;