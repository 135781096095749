import React, {useState} from 'react';
import {Alert, Box, Button, Chip, Snackbar, Typography, useTheme} from "@mui/material";
import {tokens} from "../../../theme";
import {Done, Reply} from "@mui/icons-material";
import {useSendQuestionToAnalystMutation} from "../../../store/emptySplitApi";
import {MessageContent, MessageTitle} from "../styled";
import {useTranslation} from "react-i18next";

const ModelUncertaintyMessageComponent = ({message}) => {
    const { t, i18n } = useTranslation();
    const {text, isSentToAnalyst, responseConfidence} = message;
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [sendQuestionToAnalyst, {isLoading}] = useSendQuestionToAnalystMutation();

    const [snackbarState, setSnackbarState] = useState({
        open: false,
        success: false,
        message: '',
    });
    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarState({
            ...snackbarState,
            open: false,
        });
    };

    const sendQuestionToAnalystHandler = async () => {
        try {
            let body = {
                conversationId: message.conversation,
                messageId: message._id,
            };
            const result = await sendQuestionToAnalyst(body);

            if (result.data.error) {
                setSnackbarState({open: true, success: false, message: 'Произошла ошибка отправки задачи аналитику!'});
            } else {
                setSnackbarState({open: true, success: true, message: 'Задача успешно отправлена аналитику!'});
            }

        } catch (error) {
            setSnackbarState({open: true, success: false, message: 'Произошла ошибка отправки задачи аналитику!'});
        }
    }

    return (
        <Box
            sx={{
                "& .MuiChip-root:hover": {
                    color: isSentToAnalyst ? colors.greenAccent[600] : colors.redAccent[500],
                },
            }}
        >
            <MessageTitle>
                Ассистент: Слабая уверенность в ответе
            </MessageTitle>
            <MessageContent>
                {text}
            </MessageContent>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    "& .MuiButton-root": {
                        color: "#FAFAFA",
                        backgroundColor: colors.aliceBlue[600],
                        fontWeight: "600",
                    },
                    "& .MuiButton-root:hover": {
                        backgroundColor: '#56a9da',
                    },
                    [theme.breakpoints.down('sm')]: {
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                    }
                }}
            >
                <Chip
                    variant="outlined"
                    label={isSentToAnalyst
                        ? t('my_chats_assistant_answer_low-confidence_send_title')
                        : t('my_chats_assistant_answer_low-confidence_sent_button')}
                    icon={isSentToAnalyst ? <Done color={colors.greenAccent[700]}/> : undefined}
                    size="medium"
                    sx={{
                        margin: "0.3rem 0 0.3rem 1.2rem",
                        p: "0.1rem",
                        color: isSentToAnalyst ? colors.greenAccent[700] : colors.aliceBlue[600],
                        fontWeight: "600",
                        fontSize: "0.9rem"
                    }}
                />
                {
                    !isSentToAnalyst && (
                        <Button
                            variant="outlined"
                            onClick={sendQuestionToAnalystHandler}
                            endIcon={<Reply sx={{color: "#FAFAFA"}}/>}
                            sx={{
                                margin: "0.3rem 0 0.3rem 1.2rem",
                            }}
                        >
                            Отправить вопрос аналитику

                        </Button>
                    )
                }
            </Box>
            <Typography sx={{fontSize: "1.2rem", ml: "1.2rem", color: 'gray'}}>
                Уверенность ответа: {responseConfidence * 100}%
            </Typography>

            <Snackbar
                open={snackbarState.open}
                autoHideDuration={1500}
                onClose={handleCloseSnackbar}
            >
                <Alert
                    severity={snackbarState.success ? "success" : "error"}
                    variant="filled"
                    sx={{width: '100%'}}
                    onClose={handleCloseSnackbar}
                >
                    {snackbarState.message}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default ModelUncertaintyMessageComponent;