import i18n from "i18next";
import {initReactI18next} from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
    en: {
        translation: {
            "welcome_title": "Welcome to STRELLA!",
            "welcome_subtitle": "Enter your account details",
            "login_button": "Log In",
            "main_title": "The great and unique decision-making solution - Strella!",
            "main_card1_title": "Process Control",
            "main_card1_subtitle": "Creating SQL queries to monitor process issues such as delays, production incidents, supplier problems, customer reporting.",
            "main_card2_title": "Employee Hiring",
            "main_card2_subtitle": "Developing SQL queries to compare employee hiring data, such as time, onboarding stage, turnover, HR budget.",
            "main_card3_title": "Analytical Insights",
            "main_card3_subtitle": "Creating SQL queries to visualize data graphs, build knowledge graphs, visually assess insights, and establish connections between data.",
            "main_card4_title": "Effective Management",
            "main_card4_subtitle": "Creating SQL queries to track the status of ongoing processes at various stages of processing and comparison.",
            "sidebar_my_chats": "My Chats",
            "sidebar_split_management": "Management",
            "sidebar_split_general": "General",
            "sidebar_organization_management": "Organization Management",
            "sidebar_create_organization": "Create Organization",
            "sidebar_teams_management": "Teams Management",
            "sidebar_create_team": "Create Team",
            "sidebar_users_management": "User Management",
            "sidebar_create_user": "Create User",
            "sidebar_database_connections_management": "Database Connections Management",
            "sidebar_create_database_connection": "Create Database Connection",
            "sidebar_logout": "Log Out",
            "sidebar_lite_mode": "Light Mode",
            "sidebar_dark_mode": "Dark Mode",
            "my_chats_sidebar_title": "Active Chats",
            "my_chats_sidebar_create_chat": "Create New Chat",
            "my_chats_empty_page_title": "Select a chat from the list on the left or create a new one!",
            "my_chats_empty_page_button": "Create New Chat",
            "my_chats_creation_popup_title": "Create New Chat",
            "my_chats_creation_popup_title_input": "Chat Title",
            "my_chats_creation_popup_title_input_placeholder": "Chat Title",
            "my_chats_creation_popup_db_connection_input": "Database Connection",
            "my_chats_creation_popup_cancel_button": "Cancel",
            "my_chats_creation_popup_create_button": "Create New Chat",
            "my_chats_creation_popup_create_success_toaster": "Chat successfully created!",
            "my_chats_creation_popup_create_fail_toaster": "Chat creation failed!",
            "my_chats_chat_input_placeholder": "Your message to the assistant",
            "my_chats_you_title": "You",
            "my_chats_assistant_answer_low-confidence_title": "Low Confidence in Response",
            "my_chats_assistant_answer_low-confidence_subtitle": "I'm not confident in my answer. You can consult an analyst with this question.",
            "my_chats_assistant_answer_low-confidence_send_title": "Sent to Data Analyst",
            "my_chats_assistant_answer_low-confidence_sent_button": "Not Sent to Data Analyst",
            "my_chats_assistant_answer_low-confidence_toaster_task_sent_success": 'Data Analyst task deletion error occurred!',
            "my_chats_assistant_answer_low-confidence_toaster_task_sent_failure": 'Task successfully revoked from the task tracker!',
            "my_chats_assistant_answer_low-confidence_confidence-text": "Confidence Level",
            "my_chats_assistant_answer_answer_title": "Assistant: Result",
            "my_chats_assistant_answer_answer_subtitle_sql": "SQL Query",
            "my_chats_assistant_answer_answer_subtitle_table": "Result:",
            "my_chats_assistant_answer_answer_subtitle_explanation": "Explanation",
            "my_chats_analytics_window_show_button": "Queries to Data Analyst",
            "my_chats_analytics_window_delete_button_tooltip": "Delete chat",
            "my_chats_analytics_window_delete_button_snackbar_success": "Chat is deleted successfully",
            "my_chats_analytics_window_delete_button_snackbar_failure": "Error while deleting chat",
            "my_chats_analytics_window_title": "Queries to Data Analyst",
            "my_chats_analytics_window_close_button": "Close",
            "my_chats_analytics_window_cancel_request": "Cancel Request",
            "my_chats_analytics_window_cancel_request_hint": "When a request is no longer relevant, it can be withdrawn from the analyst task system",
            "my_chats_analytics_window_increase_priority": "Increase Priority",
            "my_chats_analytics_window_increase_priority_hint": "When a request has a high priority and needs to be resolved urgently, you can increase the priority of an existing task for the analyst",
            "organization_management_title": "Organizations",
            "organization_management_subtitle": "Manage existing organizations",
            "organization_management_column1_title": "Organization Name",
            "organization_management_column2_title": "Admins",
            "organization_management_column3_title": "Actions",
            "organization_management_action_delete_title": "You are about to delete the organization",
            "organization_management_action_delete_text": "Are you sure you want to delete the organization?",
            "organization_management_action_delete_submit_button": "Yes",
            "team_management_title": "Teams",
            "team_management_subtitle": "Manage existing teams",
            "team_management_column1_title": "Team Name",
            "team_management_column2_title": "Organization",
            "team_management_column3_title": "Actions",
            "team_management_action_delete_title": "You are about to delete the team",
            "team_management_action_delete_text": "Are you sure you want to delete the team?",
            "team_management_action_delete_submit_button": "Yes",
            "user_management_title": "Users",
            "user_management_subtitle": "Manage existing users",
            "user_management_column1_title": "Full Name",
            "user_management_column2_title": "Email",
            "user_management_column3_title": "Role",
            "user_management_column4_title": "Team",
            "user_management_column5_title": "Actions",
            "user_management_action_delete_title": "You are about to delete the user",
            "user_management_action_delete_text": "Are you sure you want to delete the user?",
            "user_management_action_delete_submit_button": "Yes",
            "database_connection_management_title": "Database Connections",
            "database_connection_management_subtitle": "Manage existing database connections",
            "database_connection_management_column1_title": "Name",
            "database_connection_management_column2_title": "Team",
            "database_connection_management_column3_title": "DB Type",
            "database_connection_management_column4_title": "Host",
            "database_connection_management_column5_title": "Port",
            "database_connection_management_column6_title": "Username",
            "database_connection_management_column7_title": "Database",
            "database_connection_management_column8_title": "Actions",
            "database_connection_management_action_delete_title": "You are about to delete the database connection",
            "database_connection_management_action_delete_text": "Are you sure you want to delete the database connection?",
            "database_connection_management_action_delete_submit_button": "Yes",
            "organization_creation_title": "Create Organization",
            "organization_creation_subtitle": "Create a new organization",
            "organization_creation_input_field1_title": "Organization Name",
            "organization_creation_submit_button": "Create New Organization",
            "organization_creation_field1_required_message": "Organization name is a required field",
            "organization_creation_success_toaster": 'Organization successfully created!',
            "team_creation_title": "Create Team",
            "team_creation_subtitle": "Create a new team within the organization",
            "team_creation_input_field1_title": "Team Name",
            "team_creation_input_field2_title": "Organization",
            "team_creation_submit_button": "Create New Team",
            "team_creation_field1_required_message": "Team name is a required field",
            "team_creation_field2_required_message": "Organization is a required field",
            "team_creation_success_toaster": 'Team successfully created!',
            "user_creation_title": "Create User",
            "user_creation_subtitle": "Create a new user within the organization",
            "user_creation_input_field1_title": "Full Name",
            "user_creation_input_field2_title": "Email",
            "user_creation_input_field3_title": "Role",
            "user_creation_input_field4_title": "Password",
            "user_creation_input_field5_title": "Team",
            "user_creation_input_field6_title": "Organization",
            "user_creation_submit_button": "Create New User",
            "user_creation_field1_required_message": "Full name is a required field",
            "user_creation_field1_min_length_message": "Full name must be at least 5 characters",
            "user_creation_field1_matches_message": "Name should not include special characters or numbers",
            "user_creation_field2_required_message": "Email is a required field",
            "user_creation_field2_matches_message": "Invalid email",
            "user_creation_field3_required_message": "Role is a required field",
            "user_creation_field4_required_message": "Password is a required field",
            "user_creation_field5_required_message": "Organization is a required field",
            "user_creation_field6_required_message": "Team is a required field",
            "user_creation_success_toaster": 'User successfully created!',
            "user_creation_label1": 'Organization Admin',
            "user_creation_label2": 'Database Connections Admin',
            "user_creation_label3": 'User',
            "database_connection_creation_title": "Create Database Connection",
            "database_connection_creation_subtitle": "Create a new database connection within the team",
            "database_connection_creation_input_field1_title": "Connection Name",
            "database_connection_creation_input_field2_title": "Team",
            "database_connection_creation_input_field3_title": "DB Type",
            "database_connection_creation_input_field4_title": "Host",
            "database_connection_creation_input_field5_title": "Port",
            "database_connection_creation_input_field6_title": "Username",
            "database_connection_creation_input_field7_title": "Password",
            "database_connection_creation_input_field8_title": "Database Name",
            "database_connection_creation_submit_button": "Create New Connection",
            "database_connection_creation_field1_required_message": "Connection name is a required field",
            "database_connection_creation_field2_required_message": "Team is a required field",
            "database_connection_creation_field3_required_message": "DB type is a required field",
            "database_connection_creation_field4_required_message": "Team name is a required field",
            "database_connection_creation_field5_required_message": "Port is a required field",
            "database_connection_creation_field6_required_message": "Connection login is a required field",
            "database_connection_creation_field7_required_message": "Connection password is a required field",
            "database_connection_creation_field8_required_message": "Database name is a required field",
            "database_connection_creation_test_connection_button": "Test Connection",
            "database_connection_creation_success_toaster": 'Database connection is working successfully!',
            "database_connection_creation_failure_toaster": 'Unable to connect to DB!',
            "database_connection_test_connection_success": 'Database connection is working successfully!',
            "database_connection_test_connection_failure": 'Insufficient parameters to test the connection!',
        }
    },
    ru: {
        translation: {
            "welcome_title": "Добро пожаловать в STRELLA!",
            "welcome_subtitle": "Введите данные своей учётной записи",
            "login_button": "Войти",
            "main_title": "Великое и неповторимое решение для принятия решений - Strella!",
            "main_card1_title": "Контроль процессов",
            "main_card1_subtitle": "Создание SQL-запросов для отслеживания проблем с процессами, таких как задержки, инциденты в производстве, проблемы с поставщиками, отчётность по клиентам.",
            "main_card2_title": "Найм сотрудников",
            "main_card2_subtitle": "Разработка SQL-запросов для сравнения данных о найме сотрудников, таких как время, стадия процесса онбординга, текучка, бюджет кадров",
            "main_card3_title": "Аналитические выводы",
            "main_card3_subtitle": "Создание SQL-запросов для визуализации графов данных, построения графов знаний, визуальная оценка выводов и построение связи между данными",
            "main_card4_title": "Эффективное управление",
            "main_card4_subtitle": "Создание SQL-запросов для отслеживания статуса выполняемых процессов на различных стадиях обработки и сравнения",
            "sidebar_my_chats": "Мои Чаты",
            "sidebar_split_management": "Управление",
            "sidebar_split_general": "Общие",
            "sidebar_organization_management": "Управление Организациями",
            "sidebar_create_organization": "Создать Организацию",
            "sidebar_teams_management": "Управление Командами",
            "sidebar_create_team": "Создать Команду",
            "sidebar_users_management": "Управление пользователями",
            "sidebar_create_user": "Создать Пользователя",
            "sidebar_database_connections_management": "Управление БД подключениями",
            "sidebar_create_database_connection": "Создать БД подключение",
            "sidebar_logout": "Выйти",
            "sidebar_lite_mode": "Светлая тема",
            "sidebar_dark_mode": "Тёмная тема",
            "my_chats_sidebar_title": "Активные чаты",
            "my_chats_sidebar_create_chat": "Создать новый чат",
            "my_chats_empty_page_title": "Выберите чат из списка слева или создайте новый!",
            "my_chats_empty_page_button": "Создать новый чат",
            "my_chats_creation_popup_title": "Создать новый чат",
            "my_chats_creation_popup_title_input": "Заголовок чата",
            "my_chats_creation_popup_title_input_placeholder": "Заголовок чата",
            "my_chats_creation_popup_db_connection_input": "БД Подключение",
            "my_chats_creation_popup_cancel_button": "Отмена",
            "my_chats_creation_popup_create_button": "Создать новый чат",
            "my_chats_creation_popup_create_success_toaster": "Чат успешно создан!",
            "my_chats_creation_popup_create_fail_toaster": "Создание чата неудачно!",
            "my_chats_chat_input_placeholder": "Ваше сообщение ассистенту",
            "my_chats_you_title": "Вы",
            "my_chats_assistant_answer_low-confidence_title": "Слабая уверенность в ответе",
            "my_chats_assistant_answer_low-confidence_subtitle": "Я не уверен в своём ответе. Мы можете обратиться к аналитику с этим вопросом.",
            "my_chats_assistant_answer_low-confidence_send_title": "Отправлено аналитику",
            "my_chats_assistant_answer_low-confidence_sent_button": "Не отправлено аналитику",
            "my_chats_assistant_answer_low-confidence_toaster_task_sent_success": 'Произошла ошибка удаления задачи аналитика!',
            "my_chats_assistant_answer_low-confidence_toaster_task_sent_failure": 'Задача успешно отозвана из таска трекера!',
            "my_chats_assistant_answer_low-confidence_confidence-text": "Уверенность ответа",
            "my_chats_assistant_answer_answer_title": "Ассистент: Результат",
            "my_chats_assistant_answer_answer_subtitle_sql": "SQL-запрос",
            "my_chats_assistant_answer_answer_subtitle_table": "Результат:",
            "my_chats_assistant_answer_answer_subtitle_explanation": "Объяснение",
            "my_chats_analytics_window_show_button": "Запросы аналитику",
            "my_chats_analytics_window_delete_button_tooltip": "Удалить чат",
            "my_chats_analytics_window_delete_button_snackbar_success": "Чат успешно удалён",
            "my_chats_analytics_window_delete_button_snackbar_failure": "Ошибка при удалении чата",
            "my_chats_analytics_window_title": "Запросы аналитику",
            "my_chats_analytics_window_close_button": "Закрыть",
            "my_chats_analytics_window_cancel_request": "Отменить запрос",
            "my_chats_analytics_window_cancel_request_hint": "Когда запрос становится неактуальным, можно его отозвать из системы задач для аналитиков",
            "my_chats_analytics_window_increase_priority": "Повысить приоритет",
            "my_chats_analytics_window_increase_priority_hint": "Когда запрос имеет высокий приоритет и должен быть решён в кратчайшие сроки, можно повысить приоритет уже существущей задачи для аналитика",
            "organization_management_title": "Организации",
            "organization_management_subtitle": "Управление существующими организациями",
            "organization_management_column1_title": "Имя организации",
            "organization_management_column2_title": "Админы",
            "organization_management_column3_title": "Действия",
            "organization_management_action_delete_title": "Вы собираетесь удалить организацию",
            "organization_management_action_delete_text": "Вы уверены, что хотите удалить организацию?",
            "organization_management_action_delete_submit_button": "Да",
            "team_management_title": "Команды",
            "team_management_subtitle": "Управление существующими командами",
            "team_management_column1_title": "Имя команды",
            "team_management_column2_title": "Организация",
            "team_management_column3_title": "Действия",
            "team_management_action_delete_title": "Вы собираетесь удалить команду",
            "team_management_action_delete_text": "Вы уверены, что хотите удалить команду?",
            "team_management_action_delete_submit_button": "Да",
            "user_management_title": "Пользователи",
            "user_management_subtitle": "Управление существующими пользователями",
            "user_management_column1_title": "Полное Имя",
            "user_management_column2_title": "Email",
            "user_management_column3_title": "Роль",
            "user_management_column4_title": "Команда",
            "user_management_column5_title": "Действия",
            "user_management_action_delete_title": "Вы собираетесь удалить пользователя",
            "user_management_action_delete_text": "Вы уверены, что хотите удалить пользователя?",
            "user_management_action_delete_submit_button": "Да",
            "database_connection_management_title": "БД Подключения",
            "database_connection_management_subtitle": "Управление существующими БД подключениями\n",
            "database_connection_management_column1_title": "Название",
            "database_connection_management_column2_title": "Команда",
            "database_connection_management_column3_title": "Тип БД",
            "database_connection_management_column4_title": "Host",
            "database_connection_management_column5_title": "Port",
            "database_connection_management_column6_title": "Username",
            "database_connection_management_column7_title": "Database",
            "database_connection_management_column8_title": "Действия",
            "database_connection_management_action_delete_title": "Вы собираетесь удалить БД подключение",
            "database_connection_management_action_delete_text": "Вы уверены, что хотите удалить БД подключение?",
            "database_connection_management_action_delete_submit_button": "Да",
            "organization_creation_title": "Создать организацию",
            "organization_creation_subtitle": "Создать новую организацию",
            "organization_creation_input_field1_title": "Название организации",
            "organization_creation_submit_button": "Создать новую организацию",
            "organization_creation_field1_required_message": "Название организации обязательное поле",
            "organization_creation_success_toaster": 'Организация успешно создана!',
            "team_creation_title": "Создать команду",
            "team_creation_subtitle": "Создать новую команду внутри организации",
            "team_creation_input_field1_title": "Название команды",
            "team_creation_input_field2_title": "Организация",
            "team_creation_submit_button": "Создать новую команду",
            "team_creation_field1_required_message": "Название команды обязательное поле",
            "team_creation_field2_required_message": "Организация обязательное поле",
            "team_creation_success_toaster": 'Организация успешно создана!',
            "user_creation_title": "Создать Пользователя",
            "user_creation_subtitle": "Создать нового пользователя в организации",
            "user_creation_input_field1_title": "Полное имя",
            "user_creation_input_field2_title": "Email",
            "user_creation_input_field3_title": "Роль",
            "user_creation_input_field4_title": "Пароль",
            "user_creation_input_field5_title": "Команда",
            "user_creation_input_field6_title": "Организация",
            "user_creation_submit_button": "Создать нового пользователя",
            "user_creation_field1_required_message": "Полное имя обязательное поле",
            "user_creation_field1_min_length_message": "Полное имя должны быть не короче 5 символов",
            "user_creation_field1_matches_message": "Имя не должно включать в себя специальных символов и цифр",
            "user_creation_field2_required_message": "Email обязательное поле",
            "user_creation_field2_matches_message": "Некорректный email",
            "user_creation_field3_required_message": "Роль обязательное поле",
            "user_creation_field4_required_message": "Пароль обязательное поле",
            "user_creation_field5_required_message": "Организация обязательное поле",
            "user_creation_field6_required_message": "Команда обязательное поле",
            "user_creation_success_toaster": 'Пользователь успешно создан!',
            "user_creation_label1": 'Админ Организации',
            "user_creation_label2": 'Админ БД Подключений',
            "user_creation_label3": 'Пользователь',
            "database_connection_creation_title": "Создать БД Подключение",
            "database_connection_creation_subtitle": "Создать новую БД подключение внутри команды",
            "database_connection_creation_input_field1_title": "Название подключения",
            "database_connection_creation_input_field2_title": "Команда",
            "database_connection_creation_input_field3_title": "Тип БД",
            "database_connection_creation_input_field4_title": "Host",
            "database_connection_creation_input_field5_title": "Порт",
            "database_connection_creation_input_field6_title": "Username",
            "database_connection_creation_input_field7_title": "Пароль",
            "database_connection_creation_input_field8_title": "Название базы данных",
            "database_connection_creation_submit_button": "Создать новое подключение",
            "database_connection_creation_field1_required_message": "Название подключения обязательное поле",
            "database_connection_creation_field2_required_message": "Команда обязательное поле",
            "database_connection_creation_field3_required_message": "Тип БД обязательное поле",
            "database_connection_creation_field4_required_message": "Название команды обязательное поле",
            "database_connection_creation_field5_required_message": "Порт обязательное поле",
            "database_connection_creation_field6_required_message": "Логин подключения обязательное поле",
            "database_connection_creation_field7_required_message": "Пароль подключения обязательное поле",
            "database_connection_creation_field8_required_message": "Название БД обязательное поле",
            "database_connection_creation_test_connection_button": "Протестировать подключение",
            "database_connection_creation_success_toaster": 'Подключение к БД успешно работает!',
            "database_connection_creation_failure_toaster": 'Не удалось подключиться к БД!',
            "database_connection_test_connection_success": 'Подключение к БД успешно работает!',
            "database_connection_test_connection_failure": 'Не хватает параметров, чтобы протестировать подключение!',
        }
    }
};

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: "ru",
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;