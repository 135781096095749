import React from 'react';
import {useLocation} from "react-router-dom";
import {useMediaQuery, useTheme} from "@mui/material";

import LoginFormDesktop from "./LoginFormDesktop";
import LoginFormMobile from "./LoginFormMobile";
import {LoginContainer} from "./styled";
import './Login.css';

const Login = () => {
    const theme = useTheme();

    const location = useLocation();
    const fromPage = location.state?.from?.pathname || '/';

    const matches = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <LoginContainer>
            {matches
                ? <LoginFormMobile fromPage={fromPage}/>
                : <LoginFormDesktop fromPage={fromPage}/>}
        </LoginContainer>
    );
}

export default Login;