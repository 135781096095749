import {Box, styled, Typography} from "@mui/material";

export const StyledLoginH3 = styled(Typography)(({theme}) => ({
    fontSize: '1.5rem',
    fontWeight: 500,
    [theme.breakpoints.up('lg')]: {
        fontSize: '2rem',
    },
}));

export const StyledLoginH4 = styled(Typography)(({theme}) => ({
    fontSize: '1rem',
    fontWeight: 500,
    [theme.breakpoints.up('lg')]: {
        fontSize: '1.5rem',
    },
}));

export const LoginContainer = styled(Box)(({theme}) => ({
    display: "flex",
    flexWrap: 'nowrap',
    flexDirection: 'row',
    alignItems: 'stretch',
    background: '#FAFAFA',
    color: '#040404',
    height: '100vh',
    [theme.breakpoints.down('md')]: {
        alignItems: 'center',
    }
}));