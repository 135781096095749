import {useSelector} from "react-redux";
import {ROLES} from "../util/constants";
import Page404 from "../scenes/global/Page404";

const accessRoles = {
    [ROLES.USER]: [ROLES.ADMIN, ROLES.ORGANIZATION_ADMIN, ROLES.DATABASE_ADMIN, ROLES.USER],
    [ROLES.DATABASE_ADMIN]: [ROLES.ADMIN, ROLES.ORGANIZATION_ADMIN, ROLES.DATABASE_ADMIN],
    [ROLES.ORGANIZATION_ADMIN]: [ROLES.ADMIN, ROLES.ORGANIZATION_ADMIN],
    [ROLES.ADMIN]: [ROLES.ADMIN],
};

const RequireRole = ({ leastAllowedRole, children }) => {
    const { role } = useSelector(state => state.auth.user.user);

    if (accessRoles[leastAllowedRole]?.includes(role)) {
        return children;
    }

    return <Page404 />;
};

export default RequireRole;