import {Box, OutlinedInput, Typography, useTheme} from "@mui/material";
import React from "react";
import {tokens} from "../theme";
import {Controller} from "react-hook-form";

const StyledFormInput = ({label, control, name, errors, ...props}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
        <Box
            sx={{
                gridColumn: "span 4",
                display: 'flex',
                flexDirection: "column",
            }}
        >
            <Typography
                sx={{
                    ml: "0.3rem",
                    mb: "0.3rem",
                    fontSize: "1.2rem",
                }}
            >
                {label}
            </Typography>
            <Controller
                render={({field}) => (
                    <OutlinedInput
                        placeholder={label}
                        sx={{
                            fontSize: "1rem"
                        }}
                        {...field}
                        {...props}
                    />
                )
                }
                name={name}
                control={control}
            />
            {errors && (
                <Box
                    color="#ED0131"
                    fontSize="16px"
                >
                    {errors.message}
                </Box>
            )}
        </Box>
    )
}

export default StyledFormInput;