import userEndpointsBuilder from "../userSlice/userApi";

const teamEndpointsBuilder = (builder) => {
    return {
        getTeam: builder.query({
            query: (id) => (
                {
                    url: `team/${id}`,
                    method: 'GET',
                }
            ),
            transformResponse(baseQueryReturnValue, meta, arg) {
                return baseQueryReturnValue;
            },
            transformErrorResponse(baseQueryError, meta, arg) {
                return baseQueryError;
            }
        }),
        getTeams: builder.query({
            query: () => ({
                url: 'team',
                method: 'GET',
            }),
            providesTags: (result) =>
                result
                    ? [...result.map(({ id }) => ({ type: 'Teams', id })), 'Teams']
                    : ['Teams'],
            transformResponse(baseQueryReturnValue, meta, arg) {
                return baseQueryReturnValue;
            },
        }),
        getTeamLabels: builder.query({
            query: () => ({
                url: 'team',
                method: 'GET',
            }),
            providesTags: (result) =>
                result
                    ? [...result.map(({ id }) => ({ type: 'Teams', id })), 'Teams']
                    : ['Teams'],
            transformResponse(baseQueryReturnValue, meta, arg) {
                return baseQueryReturnValue.map(({_id, name}) => ({label: name, team: _id}));
            },
        }),
        createTeam: builder.mutation({
            query: (body) => ({
                url: 'team',
                method: 'POST',
                body
            }),
            invalidatesTags: ['Teams'],
            transformResponse(baseQueryReturnValue, meta, arg) {
                return baseQueryReturnValue;
            },
            transformErrorResponse(baseQueryError, meta, arg) {
                return baseQueryError;
            }
        }),
        updateTeam: builder.mutation({
            query: ({id, body}) => ({
                url: `team/${id}`,
                method: 'PUT',
                body
            }),
            invalidatesTags: ['Teams']
        }),
        deleteTeam: builder.mutation({
            query: (id) => ({
                url: `team/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Teams']
        }),
    }
};

export default teamEndpointsBuilder;
