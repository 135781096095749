import {styled, Typography} from "@mui/material";
import {TextareaAutosize} from "@mui/base/TextareaAutosize";

export const MessageTitle = styled(Typography)(({theme}) => ({
    fontWeight: 600,
    fontSize: "24px",
    [theme.breakpoints.down('md')]: {
        fontSize: "18px"
    }
}));


export const MessageContent = styled(Typography)(({theme}) => ({
    fontSize: "22px",
    [theme.breakpoints.down('md')]: {
        fontSize: "16px"
    }
}));


export const MessageErrorTitle = styled(Typography)(({theme}) => ({
    fontSize: "20px",
    marginLeft: '20px',
    color: 'red',
    [theme.breakpoints.down('md')]: {
        fontSize: "18px"
    }
}));

export const MessageErrorContent = styled(Typography)(({theme}) => ({
    fontSize: "18px",
    marginLeft: '20px',
    color: 'gray',
    [theme.breakpoints.down('md')]: {
        fontSize: "14px"
    }
}));

export const ConversationMessageInput = styled(TextareaAutosize)(({theme}) => ({
    fontSize: '16px',
    [theme.breakpoints.down('sm')]: {
        fontSize: '14px',
    },
    lineHeight: 1.5,
    padding: '5px',
    background: 'transparent',
    color: '#FAFAFA',
    minWidth: '95%',
    '&:hover': {
        borderColor: '#3399FF',
    },

    '&:focus': {
        outline: 0,
        borderColor: '#3399FF',
        boxShadow: '0 0 0 3px #0072E5',
    }
}));