import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'
import authEndpointsBuilder from "./authSlice/authApi";
import userEndpointsBuilder from "./userSlice/userApi";
import organizationEndpointsBuilder from "./organizationSlice/organizationApi";
import teamEndpointsBuilder from "./teamSlice/teamApi";
import conversationEndpointsBuilder from "./conversationSlice/conversationApi";
import dbConnectionEndpointsBuilder from "./dbConnectionSlice/dbConnectionApi";

const baseQuery = fetchBaseQuery({
    baseUrl: '/server/api/',
    // baseUrl: process.env.REACT_APP_STAGE === 'prod' ? '/server/api/' : `${process.env.REACT_APP_API_URL}/api/`,
    prepareHeaders: (headers, {getState, endpoint}) => {
        if (['login', 'refresh'].includes(endpoint)) {
            return headers;
        }

        const token = getState().auth?.user?.accessToken;

        if (token) {
            headers.set('authorization', `Bearer ${token}`);
        }
        return headers;
    },
})

export const api = createApi({
    reducerPath: 'api',
    tagTypes: [
        "Users",
        "Teams",
        "Organizations",
        "Messages",
        "MessagesSentToAnalyst",
        "Conversations",
        "Conversation",
        "Auth",
        "DbConnections",
        "DbConnectionLabels",
    ],
    // baseQuery: fetchBaseQuery({
    //     baseUrl: 'http://localhost:5000/api/',
    //     prepareHeaders: (headers, {getState}) => {
    //         const token = getState().auth?.user?.accessToken;
    //
    //         if (token) {
    //             headers.set('authorization', `Bearer ${token}`);
    //         }
    //         return headers;
    //     },
    // }),
    baseQuery: baseQuery,
    endpoints: (builder) => ({
        // todo: on level of endpoints put check of roles and permissions for queries and mutations
        ...authEndpointsBuilder(builder),
        ...userEndpointsBuilder(builder),
        ...teamEndpointsBuilder(builder),
        ...organizationEndpointsBuilder(builder),
        ...conversationEndpointsBuilder(builder),
        ...dbConnectionEndpointsBuilder(builder),
    }),
})

export const {
    useLoginMutation,
    useRefreshMutation,
    useLogoutMutation,
    useRegisterMutation,
    useGetMeQuery,
//     User CRUD
    useGetUserQuery,
    useGetUsersQuery,
    useCreateUserMutation,
    useUpdateUserMutation,
    useDeleteUserMutation,
//     Team CRUD
    useGetTeamQuery,
    useGetTeamsQuery,
    useGetTeamLabelsQuery,
    useCreateTeamMutation,
    useUpdateTeamMutation,
    useDeleteTeamMutation,
//     organization CRUD
    useGetOrganizationQuery,
    useGetOrganizationsQuery,
    useGetOrganizationLabelsQuery,
    useCreateOrganizationMutation,
    useUpdateOrganizationMutation,
    useDeleteOrganizationMutation,
//     Conversation CRUD
    useGetConversationQuery,
    useGetConversationsQuery,
    useStartConversationMutation,
    useHideConversationMutation,
    useSendMessageMutation,
    useGetConversationMessagesQuery,
    useSendQuestionToAnalystMutation,
    useCancelQuestionToAnalystMutation,
    useGetMessagesSentToAnalystQuery,
//     DB Connection CRUD
    useCreateDbConnectionMutation,
    useTestDbConnectionMutation,
    useGetDbConnectionsQuery,
    useGetDbConnectionLabelsQuery,
    useGetDbConnectionQuery,
    useUpdateDbConnectionMutation,
    useDeleteDbConnectionMutation,
} = api;