import {useLocation, useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import { jwtDecode } from 'jwt-decode';

const accessTokenIsValid = (accessToken) => {
    try {
        const decoded = jwtDecode(accessToken);
        const currentTime = Date.now() / 1000; // Convert to seconds

        return decoded.exp > currentTime;
    } catch (error) {
        return false;
    }
};

const RequireAuth = ({children}) => {
    const location = useLocation();
    const navigate = useNavigate();

    const {user, isAuthenticated} = useSelector(state => state.auth);

    if (!isAuthenticated || !user || !user.accessToken || !accessTokenIsValid(user.accessToken)) {
        return navigate('/login', {state: {from: location}});
    }

    return children;
}

export default RequireAuth;