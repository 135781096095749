import {useTheme} from "@mui/material";
import {tokens} from "../../theme";
import {MenuItem} from "react-pro-sidebar";
import {NavLink} from "react-router-dom";


const SidebarItem = ({title, to, icon, isSidebarOpen, onClickFunction, ...props}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const linkStyle = ({isActive}) => {
        return {
            textDecoration: "none",
            whiteSpace: !isSidebarOpen ? "nowrap" : "normal",
            color: isActive ? "#3da58a" : "inherit",
        }
    }

    if (onClickFunction) {
        return (
            <MenuItem
                // sx={{color: colors.grey[100]}}
                icon={icon}
                {...props}
                onClick={onClickFunction}
            >
                <div>
                    {!isSidebarOpen ? "" : title}
                </div>
            </MenuItem>
        )
    }

    return (
        <MenuItem
            // sx={{color: colors.grey[100]}}
            icon={icon}
            {...props}
        >
            <NavLink to={to} style={linkStyle}>
                {!isSidebarOpen ? "" : title}
            </NavLink>
        </MenuItem>
    );
}

export default SidebarItem;