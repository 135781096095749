import {Box, MenuItem, Select, Typography, useTheme} from "@mui/material";
import React, {useMemo} from "react";
import {Controller} from "react-hook-form";
import {tokens} from "../theme";

const StyledMultiSelectInput = ({
                                    label,
                                    name,
                                    control,
                                    values,
                                    errors,
                                }) => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const menuItemValues = useMemo(() => {
        return values.map((v) => (
            <MenuItem
                key={v[name]}
                value={v[name]}
            >
                {v.label}
            </MenuItem>
        ))
    }, [values]);

    return (
        <Box
            display='flex'
            flexDirection="column"
            sx={{
                gridColumn: "span 4",
                "& .MuiAutocomplete-listbox": {
                    maxHeight: "10rem !important",
                },
            }}
        >
            <Controller
                render={({field}) => (
                    <>
                        <Typography
                            sx={{
                                ml: "0.3rem",
                                mb: "0.3rem",
                                fontSize: "1.2rem",
                            }}
                        >
                            {label}
                        </Typography>
                        <Select
                            fullWidth
                            {...field}
                            MenuProps={{
                                PaperProps: {
                                    sx: {
                                        backgroundColor: colors.primary[600],
                                        '& .MuiMenuItem-root': {
                                            padding: "1rem 2rem",
                                        },
                                        "& .Mui-selected": {
                                            backgroundColor: colors.primary[300],
                                        },
                                    },
                                },
                            }}
                        >
                            {menuItemValues}
                        </Select>
                    </>
                )}
                name={name}
                control={control}
            />
            {errors && (
                <Box
                    color="#ED0131"
                    fontSize="16px"
                >
                    {errors.message}
                </Box>
            )}
        </Box>
    )
};

export default StyledMultiSelectInput;