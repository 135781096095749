import "./index.css";
import {Box, Typography, useTheme} from "@mui/material";
import {tokens} from "../../theme";
import logoImage from '../../assets/asq_logo.svg';
import DescriptionCard from "./DescriptionCard";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";


const GreetingPage = (props) => {
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const navigate = useNavigate();

    return (
        <Box
            sx={{
                maxWidth: "80%",
                margin: "2rem auto",
            }}
        >
            <Box
                sx={{
                    minWidth: "100%",
                    flexGrow: "1",
                    margin: "10rem 0 0 0",
                    display: "flex",
                    [theme.breakpoints.down('md')]: {
                        margin: "1rem 0 0 0",
                    },
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "1rem"
                }}
            >
                <Box
                    sx={{
                        maxWidth: "100px",
                        [theme.breakpoints.down('lg')]: {
                            maxWidth: '80px',
                        },
                        [theme.breakpoints.down('md')]: {
                            maxWidth: '60px',
                        }
                    }}
                >
                    <img className="head__logo" alt="platfrom logo" src={logoImage}/>
                </Box>
                <Typography
                    variant="h3"
                    sx={{
                        maxWidth: "60%",
                        [theme.breakpoints.down('lg')]: {
                            fontSize: '1.2rem',
                            maxWidth: '70%',
                        },
                        [theme.breakpoints.down('md')]: {
                            fontSize: '0.9rem',
                            maxWidth: '80%',
                        }
                    }}
                >
                    {t('main_title')}
                </Typography>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexWrap: "wrap",
                }}
            >
                <DescriptionCard
                    title={t('main_card1_title')}
                    description={t('main_card1_subtitle')}
                />

                <DescriptionCard
                    title={t('main_card2_title')}
                    description={t('main_card2_subtitle')}
                />

                <DescriptionCard
                    title={t('main_card3_title')}
                    description={t('main_card3_subtitle')}
                />

                <DescriptionCard
                    title={t('main_card4_title')}
                    description={t('main_card4_subtitle')}
                />
            </Box>
        </Box>
    )
}

export default GreetingPage;