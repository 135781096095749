import {createSlice} from "@reduxjs/toolkit";

const conversationSlice = createSlice({
    name: 'conversation',
    initialState: {
        loading: false,
    },
    reducers: {},
});


export default conversationSlice.reducer;