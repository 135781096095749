import {StyledLoginH3, StyledLoginH4} from "./styled";
import LoginForm from "./LoginForm";

const LoginFormMobile = ({fromPage}) => {
    return (
        <div className="login__content">
            <StyledLoginH3>
                Добро пожаловать в STRELLA!
            </StyledLoginH3>
            <StyledLoginH4>
                Введите данные своей учётной записи
            </StyledLoginH4>

            <LoginForm fromPage={fromPage}/>
        </div>
    );
}

export default LoginFormMobile;